import { legacy_createStore as createStore, applyMiddleware } from "redux";
import reducers from "./reducers/RootReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(persistedReducer, {}, applyMiddleware());
export const persistor = persistStore(store);
