//--------------------------------------------------------------- All Imports Starts -------------------------------------------------------------------//
import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/Admin/Courses.module.css";
import BasicDetails from "../../components/Admin/AddCourse/BasicDetails";
import HorizontalTimeline from "react-horizontal-timeline";
import CustomTimeline from "../../components/Admin/AddCourse/CustomTimeline";
import { useSelector, useDispatch } from "react-redux";
import PrimaryModule from "../../components/Admin/AddCourse/PrimaryModule";
import SecondaryModule from "../../components/Admin/AddCourse/SecondaryModule";
import TertiaryModule from "../../components/Admin/AddCourse/TertiaryModule";
import backIcon from "../../assets/images/backIcon.svg";
import { useNavigate } from "react-router-dom";
//--------------------------------------------------------------- All Imports ends -------------------------------------------------------------------//

const AddCourse = ({ isEdit }) => {
  //---------------------------------------------  Initializing states, variables and hooks starts ---------------------------------------------------//
  const dispatch = useDispatch();
  let currentStep = useSelector((state) => state.CoursesReducers.step);
  const navigate = useNavigate();
  //---------------------------------------------  Initializing states, variables and hooks ends ---------------------------------------------------//

  //--------------------------------------------- Function to handle form steps starts -------------------------------------------------------------//
  const handleFormStep = (stepNumber) => {
    dispatch({ type: "ADD_COURSES_STEPS", payload: stepNumber });
  };
  //--------------------------------------------- Function to handle form steps ends -------------------------------------------------------------//

  // ---------------------------------------  useEffect For Initializing Form Step to 1 on Page Load Starts ---------------------------------//
  useEffect(() => {
    handleFormStep(1);
  }, []);

  // ---------------------------------------  useEffect For Initializing Form Step to 1 on Page Load Ends ---------------------------------//
  return (
    <div className={styles.addCoursePage}>
      <div className={styles.headerDiv}>
        <div className={styles.headerTitle}>
          {isEdit ? "Edit Course" : "Add New Course"}
        </div>
        <div
          className={styles.backDiv}
          onClick={() =>
            currentStep != 1 ? handleFormStep(currentStep - 1) : navigate("../")
          }
        >
          <img src={backIcon} />
          <span>Back</span>
        </div>
      </div>

      <div className="timeline">
        <div>
          <CustomTimeline
            handleFormStep={handleFormStep}
            currentStep={currentStep}
            isEdit={isEdit}
          />
        </div>
      </div>
      {currentStep == 1 ? (
        <BasicDetails handleFormStep={handleFormStep} isEdit={isEdit} />
      ) : currentStep == 2 ? (
        <PrimaryModule handleFormStep={handleFormStep} isEdit={isEdit} />
      ) : currentStep == 3 ? (
        <SecondaryModule handleFormStep={handleFormStep} isEdit={isEdit} />
      ) : currentStep == 4 ? (
        <TertiaryModule handleFormStep={handleFormStep} isEdit={isEdit} />
      ) : (
        ""
      )}
    </div>
  );
};

export default AddCourse;
