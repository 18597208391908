//--------------------------------------------------------------- All Imports Starts -------------------------------------------------------------------//
import React, { useState, useEffect } from "react";
import AdminHeader from "../../components/Admin/AdminHeader";
import styles from "../../assets/styles/Admin/table.module.css";
import coursesStyles from "../../assets/styles/Admin/Courses.module.css";
import search from "../../assets/images/Search.svg";
import AddIcon from "../../assets/images/AddIcon.svg";
import CoursesCard from "../../components/Admin/CoursesCard";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import useLoader from "../../hooks/useLoader";
import DeleteModal from "../../components/Admin/Modal/DeleteModal";
//--------------------------------------------------------------- All Imports Ends -------------------------------------------------------------------//

const Courses = () => {
  //---------------------------------------------  Initializing states, variables and hooks starts ---------------------------------------------------//
  const [searchInput, setSearchInput] = useState("");
  const navigation = useNavigate();
  const { isLoading, setLoader, Loader } = useLoader();
  const [courseData, setCourseData] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = useState();
  const [refresh, setRefresh] = useState(false);
  //---------------------------------------------  Initializing states, variables and hooks ends ---------------------------------------------------//

  //------------------------------------------------------- useEffect to fetch Courses Data Starts ---------------------------------------------------//
  useEffect(() => {
    setLoader(true);
    Request.get(`${BASE_URL}/courseGet?search=${searchInput}`)
      .then((res) => {
        setCourseData(res.data);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setLoader(false);
        setRefresh(false);
      });
  }, [searchInput, refresh]);
  //------------------------------------------------------- useEffect to fetch Courses Data Ends ---------------------------------------------------//

  //------------------------------------------------------- Function to delete courses starts ------------------------------------------------------//
  const handleDeleteModal = () => {
    if (selectedCourse) {
      Request.delete(`${BASE_URL}course/${selectedCourse}/`)
        .then((res) => {
          setRefresh(true);
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {
          setLoader(false);
          setDeleteModalOpen(false);
          setSelectedCourse(null);
        });
    }
  };
  //------------------------------------------------------- Function to delete courses ends ------------------------------------------------------//
  return (
    <>
      <AdminHeader title={"Course"} />
      <div className={coursesStyles.coursesPage}>
        <div className={coursesStyles.courseFilterContainer}>
          <div className={styles.searchDiv}>
            <input
              className={coursesStyles.searchInput}
              placeholder="Search"
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <img src={search} />
          </div>
          <div>
            <button
              className={coursesStyles.addCourseBtn}
              onClick={(e) => navigation("../admin/addCourse")}
            >
              <img src={AddIcon} /> Add New Course
            </button>
          </div>
        </div>

        {isLoading ? (
          <Loader type={"component"} />
        ) : (
          <>
            <DeleteModal
              open={deleteModalOpen}
              setDeleteModalOpen={setDeleteModalOpen}
              handleDeleteModal={handleDeleteModal}
              modalTitle={"Are you sure you want to delete this course?"}
            />
            <div className={coursesStyles.courseContainer}>
              {courseData?.map((course) => (
                <CoursesCard
                  courseData={course}
                  setDeleteModalOpen={setDeleteModalOpen}
                  setSelectedCourse={setSelectedCourse}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Courses;
