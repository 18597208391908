//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from 'react';
import styles from '../assets/styles/AboutUs.module.css'
import Section2 from './AboutUs/Section2';
import Section3 from './AboutUs/Section3';
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function AboutUs() {
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.section1}> 
                <p className={styles.titleText}>ABOUT US</p>   
            </div>
            <Section2 />
            <Section3 />
        </div>
    );
}

export default AboutUs;