import React, { useEffect } from "react";
import styles from "../../assets/styles/Course.module.css";
import listImgDisable from "../../assets/images/listImgDisable.png";
import gridImg from "../../assets/images/gridImg.png";
import listImg from "../../assets/images/listImg.png";
import gridImgDisable from "../../assets/images/gridImgDisable.png";
import Card from "../HomePage/Card";
import Listview from "../Course/Listview";
import AIImg from "../../assets/images/CourseAIImg.png";
import FrontEndImg from "../../assets/images/CourseFrontEndImg.png";
import BackEndImg from "../../assets/images/CourseBackEndImg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import useLoader from "../../hooks/useLoader";

let media = "media";

function Section1() {
  const navigation = useNavigate();
  const [filter, setFilter] = useState("All Categories");
  const [filteredCourses, setFilteredCourses] = useState();
  const [listView, setListView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const {
    isLoading: isCategoryLoading,
    setLoader: setCategoryLoader,
    Loader: CategoryLoader,
  } = useLoader();
  const {
    isLoading: isCourseLoading,
    setLoader: setCourseLoader,
    Loader: CourseLoader,
  } = useLoader();

  useEffect(() => {
    setCourseLoader(true);
    Request.get(`${BASE_URL}courseGet`)
      .then((res) => {
        setCourseDetails(res?.data);
        setFilteredCourses(res?.data);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setCourseLoader(false);
      });
  }, []);
  useEffect(() => {
    setCategoryLoader(true);
    Request.get(`${BASE_URL}categoryGet`)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => {
        alert("Error fetching category", err);
        console.log("Error fetching category", err);
      })
      .finally(() => {
        setCategoryLoader(false);
      });
  }, []);
  const [category, setCategory] = useState();
  const [courseDetails, setCourseDetails] = useState();
  useEffect(() => {
    !filteredCourses && setFilteredCourses(courseDetails);
    if (searchText?.length > 0) {
      let temp = [];
      courseDetails?.map((val, i) => {
        let item = {
          courseTitle: val.category,
          courseDetail: val.title,
          timeDetail: val.duration,
        };
        Object.values(item)?.map((text, i) => {
          try {
            if (text && text.toLowerCase().includes(searchText.toLowerCase())) {
              temp.push(val);
            }
          } catch (e) {
            console.log("Error=====", e);
          }
        });
      });
      let tempset = new Set(temp);
      setFilteredCourses([...tempset]);
    } else {
      setFilteredCourses(courseDetails);
    }
  }, [searchText]);
  function Items({ currentItems }) {
    return (
      <>
        {listView ? (
          <>
            <div className={styles.MainComponent}>
              <div className={styles.mainDiv}>
                {currentItems?.map((val, i) => {
                  if (filter === "All Categories") {
                    return (
                      <Listview
                        key={String(i)}
                        imageSrc={BASE_URL +  val.image}
                        courseTitle={val.category}
                        courseDetail={val.title}
                        timeDetail={val.duration}
                        cardID={val.id}
                      />
                    );
                  } else if (filter === val.category) {
                    return (
                      <Listview
                        key={String(i)}
                        imageSrc={BASE_URL +  val.image}
                        courseTitle={val.category}
                        courseDetail={val.title}
                        timeDetail={val.duration}
                        cardID={val.id}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.cardHolder}>
            {currentItems?.map((val, i) => {
              if (filter == "All Categories") {
                return (
                  <Card
                    key={String(i)}
                    imageSrc={BASE_URL + "/"  + val.image}
                    courseTitle={val.category}
                    courseDetail={val.title}
                    timeDetail={val.duration}
                    cardID={val.id}
                  />
                );
              }
              if (filter == val.category) {
                return (
                  <Card
                    key={String(i)}
                    imageSrc={BASE_URL +  val.image}
                    courseTitle={val.category}
                    courseDetail={val.title}
                    timeDetail={val.duration}
                  />
                );
              }
            })}
          </div>
        )}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching filteredCourses from another resources.
    // (This could be filteredCourses from props; or filteredCourses loaded in a local state
    // from an API endpoint with useEffect and useState)
    // setItemOffsetGlobal(itemOffset + 1)
    // setEndOffsetGlobal(endOffset)
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredCourses?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredCourses?.length / itemsPerPage);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset =
        (event.selected * itemsPerPage) % filteredCourses.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          className={styles.pagemain}
          activeClassName={styles.activeclass}
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageLinkClassName={styles.innerNum}
          previousLinkClassName={styles.innerNum}
          nextLinkClassName={styles.innerNum}
        />
      </>
    );
  }
  return (
    <>
      <div className={styles.section1}>
        <p className={styles.titleText}>COURSES</p>
      </div>
      {isCategoryLoading ? (
        <CategoryLoader type="component" />
      ) : (
        <div className={styles.secondContent}>
          <div className={styles.imgFlex}>
            <button
              onClick={() => {
                setListView(false);
              }}
            >
              {listView ? (
                <>
                  <img src={gridImgDisable} className={styles.cardImg} />
                </>
              ) : (
                <>
                  <img src={gridImg} className={styles.cardImg} />
                </>
              )}
            </button>
            <button
              onClick={() => {
                setListView(true);
              }}
            >
              {listView ? (
                <>
                  <img src={listImg} className={styles.cardImg} />
                </>
              ) : (
                <>
                  <img src={listImgDisable} className={styles.cardImg} />
                </>
              )}
            </button>
            {/* <label className={styles.showText}>Showing results</label> */}
          </div>

          <div className={styles.searchFlex}>
            <div className={styles.selectFlex}>
              <label className={styles.showText}>Filter:</label>
              <select
                id="filter"
                className={styles.selectStyle}
                onChange={(val) => {
                  setFilter(val.target.value);
                  console.log(val.target.value);
                }}
              >
                <option className={styles.optionFont}>All Categories</option>
                {category?.map((val, i) => {
                  return (
                    <option key={String(i)} className={styles.optionFont}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.searchDiv}>
              <input
                className={styles.searchStyle}
                onChange={(text) => {
                  setSearchText(text.target.value);
                }}
                placeholder="search"
              />
            </div>
          </div>
        </div>
      )}

      {/* {listView ?
            <>
                <div className={styles.MainComponent}>
                    <div className={styles.mainDiv}>
                        {filteredCourses?.length>0&&filteredCourses?.map((val, i) => {
                            if (filter === "All Categories") {
                                return (<Listview imageSrc={BASE_URL + "/" + media + "/" + val.image}
                                    courseTitle={val.category}
                                    courseDetail={val.title}
                                    timeDetail={val.duration} />)
                            } else if (filter === val.courseTitle) {
                                return (<Listview imageSrc={val.image}
                                    courseTitle={val.category}
                                    courseDetail={val.title}
                                    timeDetail={val.duration} />)
                            }
                        })}
                    </div>
                </div>
            </>
            :
            <div className={styles.cardHolder}>

                {filteredCourses?.length>0&&filteredCourses?.map((val, i) => {
                    if (filter == 'All Categories') {
                        return (<Card imageSrc={val.imageSrc}
                            courseTitle={val.courseTitle}
                            courseDetail={val.courseDetail}
                            timeDetail={val.timeDetail} />)
                    }
                    if (filter == val.courseTitle) {
                        return (<Card imageSrc={val.imageSrc}
                            courseTitle={val.courseTitle}
                            courseDetail={val.courseDetail}
                            timeDetail={val.timeDetail} />)
                    }
                })}
            </div>} */}

      {isCourseLoading ? (
        <CourseLoader type="component" />
      ) : (
        <PaginatedItems itemsPerPage={8} />
      )}
    </>
  );
}

export default Section1;
