import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/Admin/Modal.module.css";

const Modal = ({
  open,
  setModalOpen,
  handleModal,
  isModalLoading,
  ModalLoader,
}) => {
  const [textvalue, setTextValue] = useState("");

  return (
    <div
      className={`${styles.modale} ${open ? styles.opened : styles.closed}`}
      aria-hidden="true"
    >
      {isModalLoading ? (
        <ModalLoader />
      ) : (
        <div className={styles.modalDialog}>
          <div className={styles.modalHeader}>
            <span>
              Are you sure you want to {open == "Active" ? "Ban" : "Un Ban"}{" "}
              this student?
            </span>
          </div>

          {open == "Active" && (
            <div className={`${styles.modalBody} ${styles.banModal}`}>
              <label htmlFor="reason">Reason</label>
              <textarea
                name="reason"
                placeholder="Reason"
                rows={9}
                onChange={(e) => setTextValue(e.target.value)}
              />
            </div>
          )}

          <div className={styles.modalFooter}>
            <button onClick={() => handleModal(textvalue,setTextValue)}>Yes</button>
            <button onClick={() => setModalOpen(!open)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
