import React, { useContext, useState } from "react";
import styles from "../../assets/styles/CourseDetail.module.css";
import DownArrow from "../../assets/images/DownArrow.png";
import UpArrow from "../../assets/images/UpArrow.png";
import Lock from "../../assets/images/Lock.png";
import LockContentModal from "./LockContentModal";
import LockContext from "../../components/navigation/LockContext";
import dot from '../../assets/images/dot.png'
import { json } from "react-router-dom";

function CourseContentCard(modules) {
  let data = modules?.data;
  const [isActive, setIsActive] = useState(false);
  // const [islogin, setIsLogin] = useState(true);
  const loginCheck = localStorage.getItem("loginUser");

  const { showLockModal, setShowLockModal } = useContext(LockContext);

  return (
    <div className={styles.courseContentCard}>
      <div className={styles.accordion}>
        <div className={styles.accordionItem}>
          <div
            className={isActive ? styles.accordionTitle + " " + styles.activeAccordian : styles.accordionTitle}
            onClick={() => {
              setIsActive(!isActive);
              !loginCheck && setShowLockModal(true);
            }}>
            <div>{data?.module}</div>
            <div>
              {isActive ? (
                <img src={UpArrow} alt="UP Arrow" />
              ) : loginCheck ? (
                <img src={DownArrow} alt="Down Arrow" />
              ) : (
                <div className={styles.lockDiv}>
                  <img src={Lock} alt="Lock" className={styles.logo} />
                  <img src={DownArrow} alt="Down Arrow" className={styles.logo} />
                </div>
              )}
            </div>
          </div>
          {isActive && loginCheck && (
            <div className={styles.accordionContent}>
              {data?.subMod?.map((val, i) => {
                return (
                  <>
                    <p key={String(i)}> <li> {val?.name}</li></p>
                    {val?.subsubmod?.length > 0 &&
                      val?.subsubmod?.map((item, i) => {
                        return (<p key={String(i)}><div className={styles.subDotFlex}><img className={styles.subDot} src={dot} />{item?.subsubmodule}</div></p>);
                      })}
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CourseContentCard;
