import React, { useState } from "react";
import styles from "../../assets/styles/HomePage.module.css";
import clockImg from "../../assets/images/ClockImg.png";
import rating from "../../assets/images/Rating.png";
import rightArrow from "../../assets/images/RightArrow.png";
import { useNavigate } from "react-router-dom";
import emptyStar from "../../assets/images/emptyStar.png";
import star from "../../assets/images/star.png";

function Card({ imageSrc, courseTitle, courseDetail, timeDetail, cardID }) {
  const navigation = useNavigate();
  const [starRating, setStarRating] = useState(Math.random() * (5 - 3) + 3);

  return (
    <div className={styles.card}>
      <img src={imageSrc} alt="Home Backgroud Image" className={styles.cardImg} />
      <div className={styles.textContainer}>
        <span className={styles.courseTitle}>{courseTitle}</span>
        <p className={styles.courseDetail}>{courseDetail}</p>
        <div className={styles.learnAndClockFlex}>
          <div className={styles.cardFooter}>
            <img src={clockImg} alt="Clock Icon" className={styles.clockIcon} />
            <p className={styles.timeDetail}>{timeDetail}</p>
            <div className={styles.footerVL}></div>
            <div>
              <img
                onClick={() => {
                  setStarRating((i) => (i == 1 ? 0 : 1));
                }}
                src={starRating > 0 ? star : emptyStar}
                alt="Rating Icon"
                className={styles.ratingIconAll}
              />
              <img
                onClick={() => {
                  setStarRating((i) => (i == 2 ? 1 : 2));
                }}
                src={starRating > 1 ? star : emptyStar}
                alt="Rating Icon"
                className={styles.ratingIconAll}
              />
              <img
                onClick={() => {
                  setStarRating((i) => (i == 3 ? 2 : 3));
                }}
                src={starRating > 2 ? star : emptyStar}
                alt="Rating Icon"
                className={styles.ratingIconAll}
              />
              <img
                onClick={() => {
                  setStarRating((i) => (i == 4 ? 3 : 4));
                }}
                src={starRating > 3 ? star : emptyStar}
                alt="Rating Icon"
                className={styles.ratingIconAll}
              />
              <img
                onClick={() => {
                  setStarRating((i) => (i == 5 ? 4 : 5));
                }}
                src={starRating > 4 ? star : emptyStar}
                alt="Rating Icon"
                className={styles.ratingIconAll}
              />
            </div>{" "}
          </div>
          <div className={styles.learnMoreDiv}>
            <button
              className={styles.learnMoreText}
              onClick={() => {
                navigation(`/coursedetail/${cardID}`, { state: { data: cardID, rating: starRating } });
              }}
            >
              Learn More
            </button>
            <img src={rightArrow} alt="Feedback Icon" className={styles.rightArrowSet} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
