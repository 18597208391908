import xlsx from "json-as-xlsx";
import { BASE_URL } from "../components/baseURL";
import Request from "axios";

export const excelGenerator = (apiUrl, columns, name) => {
  Request.get(`${BASE_URL}${apiUrl}`)
    .then((res) => {
      let data = [
        {
          sheet: name,
          columns: columns,
          content: res.data.data,
        },
      ];

      let settings = {
        fileName: name, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };

      xlsx(data, settings);
    })
    .catch((err) => {
      alert("Error fetching clients", err);
      console.log("Error fetching clients", err);
    })
    .finally(() => {});
};
