import { useEffect, useState } from "react";
import styles from "../assets/styles/LoadingSpinner.module.css";

function useLoader() {
  const [isLoading, setIsLoading] = useState(false);
  const setLoader = (value) => {
    setIsLoading(value);
  };

  const Loader = ({ type }) => {
    useEffect(() => {
      if (isLoading && type != "component") {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    }, [isLoading]);

    return (
      <>
        {isLoading && (
          <div className={type == "component" ? styles.spinnerComponentContainer : styles.spinnerContainer}>
            <div className={styles.loadingSpinner}></div>
          </div>
        )}
      </>
    );
  };

  return {
    isLoading,
    setLoader,
    Loader,
  };
}

export default useLoader;
