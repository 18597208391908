//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

import React from "react";
import styles from "../assets/styles/Footer.module.css";
import newsLetterBackground from "../assets/images/NewsletterBG.png";
import PCSILogo from "../assets/images/PCSI-logoFooter.png";
import FooterFB from "../assets/images/FooterFB.png";
import FooterInsta from "../assets/images/FooterInsta.png";
import FooterLdn from "../assets/images/FooterLdn.png";
import mailIcon from "../assets/images/headerMailIcon.png";
import phoneIcon from "../assets/images/headerPhoneIcon.png";
import locationIcon from "../assets/images/LocationLogo.png";

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Footer({ setShowModal }) {
  return (
    <div className={styles.footerDiv}>
      {/* <div className={styles.imgDiv}>
                <img src={newsLetterBackground} alt="" className={styles.footerBgImg}/>
                <div className={styles.textAndInput}>
                    <p className={styles.imageSubText}>
                        Be the first to know about the courses and Events!
                    </p>
                    <div className={styles.emailTextboxDiv}>
                        <input type="text" placeholder="Enter your email" className={styles.inputEmail}/>
                        <button
                            className={styles.subscribeBtn}
                            id="subscribeBtn"
                            onClick={() => console.log("Pressed")}>
                        <div>
                            <p className={styles.subscribeBtnText}>Subscribe</p>
                        </div>
                </button>
                    </div>
                    
                </div>
                
            </div> */}
      <div className={styles.textDiv}>
        <div className={styles.outerFooterContentElement}>
          <img src={PCSILogo} alt=""></img>
          <p className={styles.subText}>
            We offer high quality advanced training and are unswerving to providing the training
            facilities to our students.
          </p>
        </div>
        <div className={styles.footerContent}>
          <div className={styles.footerContentElement}>
            <img src={PCSILogo} alt=""></img>
            <p className={styles.subText}>
              We offer high quality advanced training and are unswerving to providing the training
              facilities to our students.
            </p>
            {/* <div className={styles.socialIconsDiv}>
                            <div>
                                <a href="" className={styles.linkButton}>
                                    <img src={FooterFB} alt="" className={styles.footerIcons} />
                                </a>
                            </div>
                            <div>
                                <a href="" className={styles.linkButton}>
                                    <img src={FooterLdn} alt="" className={styles.footerIcons} />
                                </a>
                            </div>
                            <div>
                                <a href="" className={styles.linkButton}>
                                    <img src={FooterInsta} alt="" className={styles.footerIcons} />
                                </a>
                            </div>
                        </div> */}
          </div>
          <div className={styles.footerContentElement}>
            <p className={styles.titleText}>Links</p>
            <a href="./aboutus" className={styles.linkButton}>
              <p className={styles.subText}> About Us </p>
            </a>
            <a href="#" onClick={() => setShowModal("Register")} className={styles.linkButton}>
              <p className={styles.subText}>Become an Instructor </p>
            </a>
            <a href="./courses" className={styles.linkButton}>
              <p className={styles.subText}> Courses </p>
            </a>
            <a href="./inquiry" className={styles.linkButton}>
              <p className={styles.subText}>Inquiry </p>
            </a>
            {/* <a href="" className={styles.linkButton}>
                            <p className={styles.subText}>Events </p>    
                        </a> */}
          </div>
          <div className={styles.footerContentElement}>
            <p className={styles.titleText}>Contact Us (USA)</p>
            <a href="tel:+1 7324040466" className={styles.linkButton}>
              <img src={phoneIcon} alt="Phone Icon" className={styles.contentLogo} />
              <p className={styles.subText2}> (+1) 732 404 0466 </p>
            </a>
            <a href="mailto:info@paramacademy.com" className={styles.linkButton}>
              <img src={mailIcon} alt="Mail Icon" className={styles.contentLogo} />
              <p className={styles.subText2 + " " + styles.forLineBreak}>info@paramacademy.com </p>
            </a>
            <a href="" className={styles.linkButton2}>
              <img src={locationIcon} alt="Location Icon" className={styles.contentLogo2} />
              <p className={styles.subText}>
                200 Middlesex Essex Turnpike #110, Iselin, NJ 08830, United States
              </p>
            </a>
          </div>
          <div className={styles.footerContentElement}>
            <p className={styles.titleText}>Contact Us (INDIA)</p>
            <a href="tel:+91 7016693717" className={styles.linkButton}>
              <img src={phoneIcon} alt="Phone Icon" className={styles.contentLogo} />
              <p className={styles.subText2}>(+91) 701 669 3717</p>
            </a>
            <a href="mailto:infoparamedu@gmail.com" className={styles.linkButton}>
              <img src={mailIcon} alt="Mail Icon" className={styles.contentLogo} />
              <p className={styles.subText2 + " " + styles.forLineBreak}>parameduindia@gmail.com</p>
            </a>
            <a href="" className={styles.linkButton2}>
              <img src={locationIcon} alt="Location Icon" className={styles.contentLogo2} />
              <p className={styles.subText}>
                709 710 Everest Onyx, Opp Inox Multiplex, Race Course Rd, Vadodara, Gujarat 390007
              </p>
            </a>
          </div>
        </div>
        <div className={styles.copyRightDiv}>
          <p className={styles.subText}>© Copyright 2024, All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
