import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/CourseDetail.module.css";
import Card from "../HomePage/Card";
import { BASE_URL } from "../../components/baseURL";
import { useNavigate } from "react-router-dom";
import Request from "axios";
import useLoader from "../../hooks/useLoader";

let media = "media";

function Section4() {
  const [coursesDetails, setCoursesDetails] = useState();
  const { isLoading, setLoader, Loader } = useLoader();

  useEffect(() => {
    setLoader(true);
    Request.get(`${BASE_URL}courseGet`)
      .then((res) => {
        setCoursesDetails(res?.data);
      })
      .catch((err) => {
        alert("Error fetching coursedetails more courses", err);
        console.log("Error fetching coursedetails more courses", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);
  {
    coursesDetails?.map((val, i) => {
      return {
        imageSrc: val.image,
        courseTitle: val.category,
        courseDetail: val.title,
        timeDetail: val.duration,
      };
    });
  }
  return (
    <div className={styles.section4SubDiv}>
      <p className={styles.titleText}>More Courses for you</p>
      <div className={styles.cardHolder}>
        {isLoading ? (
          <Loader type="component" />
        ) : (
          coursesDetails?.map((val, i) => {
            if (i <= 3) {
              return (
                <Card
                  key={String(i)}
                  imageSrc={BASE_URL + val.image}
                  courseTitle={val.category}
                  courseDetail={val.title}
                  timeDetail={val.duration}
                  cardID={val.id}
                />
              );
            }
          })
        )}
      </div>
    </div>
  );
}

export default Section4;
