import React from "react";
import styles from "../../../assets/styles/Admin/Modal.module.css";

const DeleteModal = ({
  open,
  setDeleteModalOpen,
  handleDeleteModal,
  modalTitle
}) => {
  return (
    <div
      className={`${styles.modale} ${open ? styles.opened : styles.closed}`}
      aria-hidden="true"
    >
      <div className={styles.modalDialog}>
        <div className={styles.modalHeader}>
          <span>{modalTitle}</span>
        </div>
        <div className={styles.modalFooter}>
          <button onClick={handleDeleteModal}>Delete</button>
          <button onClick={() => setDeleteModalOpen(!open)}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
