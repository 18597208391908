import React, { useEffect } from "react";
import styles from "../../assets/styles/Admin/AdminSidebar.module.css";
import ParamLogo from "../../assets/images/PCSI-logo-admin.svg";
import { Link, useLocation } from "react-router-dom";
import coursesLogo from "../../assets/images/courses-logo.svg.svg";
import studentsLogo from "../../assets/images/students-logo.svg";
import instructorLogo from "../../assets/images/instructor-logo.svg";
import inquiryLogo from "../../assets/images/inquiry-logo.svg";
import logoutLogo from "../../assets/images/logout.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminSidebar = () => {
  const location = useLocation();
  let currentRoute = location.pathname;
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("loginUser");
    dispatch({ type: "USER_ROLE", payload: "User" });
    navigation("/");
  };

  return (
    <div className={styles.sidenav}>
      <div className={styles.logoDiv}>
        <img src={ParamLogo} />
      </div>

      <div className={styles.navLinks}>
        <div className={styles.upperLinks}>
          <Link
            to={"./admin/courses"}
            className={`${styles.navLink} ${
              currentRoute == "/admin/courses" ? styles.activeNav : ""
            }`}
          >
            <div className={styles.navOption}>
              <img src={coursesLogo} />
              <div>Courses</div>
            </div>
          </Link>
          <Link
            to={"./admin/students"}
            className={`${styles.navLink} ${
              currentRoute == "/admin/students" ? styles.activeNav : ""
            }`}
          >
            <div className={styles.navOption}>
              <img src={studentsLogo} />
              <div>Students</div>
            </div>
          </Link>
          <Link
            to={"./admin/instructor"}
            className={`${styles.navLink} ${
              currentRoute == "/admin/instructor" ? styles.activeNav : ""
            }`}
          >
            <div className={styles.navOption}>
              <img src={instructorLogo} />
              <div>Instructor</div>
            </div>
          </Link>
          <Link
            to={"./admin/inquiry"}
            className={`${styles.navLink} ${
              currentRoute == "/admin/inquiry" ? styles.activeNav : ""
            }`}
          >
            <div className={styles.navOption}>
              <img src={inquiryLogo} />
              <div>Inquiry</div>
            </div>
          </Link>
        </div>

        <div>
          <Link to={"./"} className={`${styles.navLink}`}>
            <div className={styles.navOption}>
              <img src={logoutLogo} />
              <div onClick={handleLogout}>Logout</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
