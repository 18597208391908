import {
  ADD_COURSES_STEPS,
  PRIMARY_MODULES_DATA,
  COURSES_FORM_BUILDER_SKELETON,
  ADDED_COURSE_ID,
} from "../actions/ActionCreators";

const initialState = {
  step: "1",
  primaryCoursesData: [],
  formBuilderSkeleton: [],
  addedCourseId: null,
};

export function CoursesReducers(state = initialState, action) {
  switch (action.type) {
    case ADD_COURSES_STEPS:
      return { ...state, step: action.payload };
    case PRIMARY_MODULES_DATA:
      return { ...state, primaryCoursesData: action.payload };
    case COURSES_FORM_BUILDER_SKELETON:
      return { ...state, formBuilderSkeleton: action.payload };
    case ADDED_COURSE_ID:
      return { ...state, addedCourseId: action.payload };
    default:
      return state;
    // break;
  }
}
