import React from "react";
import styles from "../../assets/styles/Admin/AdminHeader.module.css";
import bellIcon from "../../assets/images/bellIcon.svg";
import defaultUser from "../../assets/images/defaultUser.jpg";

const AdminHeader = ({ title }) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.pageTitle}>{title}</div>
      <div className={styles.profileDetails}>
        <div className={styles.belIcon}>
          <img src={bellIcon} />
          <span>6</span>
        </div>
        <div className={styles.userDetails}>
          <div className={styles.adminImage}>
            <img
              src={
                localStorage.getItem("userProfile")
                  ? localStorage.getItem("userProfile")
                  : defaultUser
              }
            />
          </div>
          <div className={styles.nameDetails}>
            <div className={styles.name}>
              {localStorage.getItem("loginUser")}
            </div>
            <div className={styles.role}>Admin</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
