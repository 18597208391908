import React, { useEffect, useState, useRef } from "react";
import HamBurgerIcon from "../assets/images/hamburger-icon.svg";
import headerLogo from "../assets/images/PCSI-logo.png";
import closeIcon from "../assets/images/close.svg";
import styles from "../assets/styles/Header.module.css";
import { Link } from "react-router-dom";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      document.body.classList.remove("no-scroll-header-overlay");
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll-header-overlay");
    } else {
      document.body.classList.remove("no-scroll-header-overlay");
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [menuRef]);
  return (
    <>
      <div
        className={styles.hamburgerContainer}
        onClick={(e) => setIsOpen(true)}
      >
        <img src={HamBurgerIcon} alt="hamburger-icon" />
        
      </div>

      <div className={isOpen?styles.greyBgMain:styles.hide}>
      
      </div>

      <main
          ref={menuRef}
          className={`${isOpen ? styles.sliderOpen : styles.sliderClosed} ${
            styles.hamburgerSlider
          } `}
        >
          <section className={styles.section}>
            <div className={styles.sectionContainer}>
              <div
                className={styles.closeBtn}
                onClick={(e) => setIsOpen(false)}
              >
                <img src={closeIcon} alt="" />
              </div>
              <div className={styles.headerLogo}>
                <img src={headerLogo} alt="" />
              </div>
            </div>
            <div className={styles.menuList}>
              <Link onClick={closeMenu} to="./">
                Home
              </Link>
              <Link onClick={closeMenu} to="./courses">
                Courses
              </Link>
              <Link onClick={closeMenu} to="./inquiry">
                Inquiry
              </Link>
              <Link onClick={closeMenu} to="./aboutus">
                About Us
              </Link>
              <Link onClick={closeMenu} to="./contactus">
                Contact Us
              </Link>
            </div>
          </section>
        </main>
      
    </>
  );
};

export default HamburgerMenu;
