/* ---------------------------------------------------------------- All imports starts ----------------------------------------------------------------- */
import React, { useState, useEffect, useRef } from "react";
import Table from "../../components/Admin/Table";
import AdminHeader from "../../components/Admin/AdminHeader";
import rowoption from "../../assets/images/rowOption.svg";
import styles from "../../assets/styles/Admin/table.module.css";
import search from "../../assets/images/Search.svg";
import DeleteModal from "../../components/Admin/Modal/DeleteModal";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import useLoader from "../../hooks/useLoader";
import Modal from "../../components/Admin/Modal/BanModal";
import { excelGenerator } from "../../utils/excelGenerator";
/* ---------------------------------------------------------------- All imports ends ----------------------------------------------------------------- */

const Students = () => {
  /* ------------------------------------------------Initialization of states, varibales and hooks starts --------------------------------------------------*/
  const [optionModalOpen, setOptionModalOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [courseValue, setCourseValue] = useState("");
  const [entriesValue, setEntriesValue] = useState("25");
  const [studentsData, setStudentsData] = useState();
  const [searchInput, setSearchInput] = useState("");
  const { isLoading, setLoader, Loader } = useLoader();
  const {
    isLoading: isModalLoading,
    setLoader: SetModalLoader,
    Loader: ModalLoader,
  } = useLoader();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [courseData, setCourseData] = useState();
  const rowOptionRef = useRef(null);
  const [modalPosition, setModalPosition] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  /* ------------------------------------------------Initialization of states, varibales and hooks ends --------------------------------------------------*/

  /* -------------------------------------------Initializing Columns and their customization to pass in table starts ----------------------------------------- */
  const columns = [
    {
      name: "Student Name",
      selector: (row) => row.fullname ?? "-",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email ?? "-",
      sortable: true,
    },
    {
      name: "Mobile No.",
      selector: (row) => row.phone ?? "-",
      sortable: true,
    },
    {
      name: "Enrolled Course",
      selector: (row) => row.enrolled_course ?? "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Enrolled Date",
      selector: (row) => row.enrolled_date ?? "-",
      sortable: true,
    },
    {
      name: "Student Status",
      selector: (row) => row.status.status_name ?? "-",
      sortable: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",

            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              justifyContent: "space-between",
            }}
          >
            {row.status.status_name ? (
              <>
                <span
                  style={{
                    backgroundColor:
                      row.status.status_name == "Active"
                        ? "#12AA85"
                        : "#D93535",
                    borderRadius: "50%",
                    height: "20px",
                    width: "20px",
                    display: "inline-block",
                  }}
                ></span>
                <span>{row.status.status_name}</span>
              </>
            ) : (
              "-"
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #959595",
              borderRadius: "50%",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <img
              className={`${
                optionModalOpen ? "rowModalOpen" : "rowModalClose"
              }`}
              src={rowoption}
              onClick={(event) => {
                if (optionModalOpen == false) {
                  const rect = event.target.getBoundingClientRect();
                  const scrollTop =
                    window.pageYOffset || document.documentElement.scrollTop;
                  const scrollLeft =
                    window.pageXOffset || document.documentElement.scrollLeft;

                  const modalTop =
                    rect.top + scrollTop + event.target.offsetHeight;
                  const modalLeft = rect.left + scrollLeft;
                  console.log(modalTop, modalLeft);
                  setModalPosition({ top: modalTop, left: modalLeft });
                  setOptionModalOpen(row.id);
                } else {
                  setOptionModalOpen(false);
                }
              }}
            />
            <div
              style={{
                display: optionModalOpen == row.id ? "flex" : "none",
                position: "fixed",
                top: `${modalPosition?.top - 20}px`,
                left: `${modalPosition?.left - 225}px`,
                height: "106px",
                width: "245px",
                flexDirection: "column",
                zIndex: 9,
              }}
              ref={rowOptionRef}
            >
              <p
                style={{
                  padding: "16px",
                  backgroundColor: "#F3F7FF",
                  border: "1px solid #4B6CB7",
                }}
                onClick={() => {
                  setModalOpen(row.status.status_name);
                  setSelectedStudent(row.id);
                }}
              >
                {row.status.status_name == "Ban"
                  ? "Un Ban Student"
                  : "Ban Student"}
              </p>

              <p
                style={{
                  padding: "16px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #4B6CB7",
                }}
                onClick={() => {
                  setDeleteModalOpen(!deleteModalOpen);
                }}
              >
                Delete Student
              </p>
            </div>
          </div>
        </div>
      ),
    },
  ];
  /* -------------------------------------------Initializing Columns and their customization to pass in table ends ----------------------------------------- */

  /*------------------------------------------------------- Function to ban/unban user starts -----------------------------------------------------------------*/
  const handleModal = (reason, setTextValue) => {
    SetModalLoader(true);
    console.log("ffff", selectedStudent);
    Request.patch(`${BASE_URL}studentsGet/${selectedStudent}/`, {
      status: modalOpen == "Ban" ? 1 : 2,
      ban_reason: reason ? reason : "",
    })
      .then((res) => {
        setOptionModalOpen(false);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setTextValue("");
        setSelectedStudent();
        setModalOpen(false);
        SetModalLoader(false);
      });
  };
  /*------------------------------------------------------- Function to ban/unban user ends -----------------------------------------------------------------*/

  /*------------------------------------------------------- Function to delete user starts -----------------------------------------------------------------*/
  const handleDeleteModal = () => {
    Request.delete(`${BASE_URL}studentsGet/${optionModalOpen}/`)
      .then((res) => {
        setOptionModalOpen(false);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setLoader(false);
        setDeleteModalOpen(false);
      });
  };
  /*------------------------------------------------------- Function to delete user ends -----------------------------------------------------------------*/

  /*-------------------------------------------------- Function to handle current page starts ------------------------------------------------------------*/
  const handleCurrentPage = (e) => {
    setCurrentPage(e);
  };
  /*-------------------------------------------------- Function to handle current page ends ------------------------------------------------------------*/

  /*-------------------------------------------------- UseState to call students data starts ------------------------------------------------------------*/
  useEffect(() => {
    setLoader(true);
    Request.get(
      `${BASE_URL}studentsGet?course=${courseValue}&page=${currentPage}&items=${entriesValue}&search=${searchInput}`
    )
      .then((res) => {
        setStudentsData(res?.data?.data);
        setTotalPages(res?.data?.total_pages);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [
    courseValue,
    searchInput,
    entriesValue,
    currentPage,
    modalOpen,
    deleteModalOpen,
  ]);
  /*-------------------------------------------------- UseState to call students data ends ------------------------------------------------------------*/

  /*-------------------------------------------------- UseState to call course data starts ------------------------------------------------------------*/
  useEffect(() => {
    setLoader(true);
    Request.get(`${BASE_URL}/courseGet`)
      .then((res) => {
        setCourseData([{ id: "", title: "All" }, ...res.data]);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);
  /*-------------------------------------------------- UseState to call course data ends ------------------------------------------------------------*/

  /*-------------------------------------------------- UseState to close row option modal starts ------------------------------------------------------------*/
  useEffect(() => {
    // Add event listener to detect clicks outside the div
    const handleClickOutside = (event) => {
      if (
        rowOptionRef.current &&
        !rowOptionRef.current.contains(event.target)
      ) {
        // Check if the click target is not the button that opens the modal
        console.log(event.target.classList.contains("rowModalClose"));
        if (!event.target.classList.contains("rowModalClose")) {
          setOptionModalOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  /*-------------------------------------------------- UseState to close row option modal ends ------------------------------------------------------------*/

  /* --------------------------------------------------------------Excel sheet column details starts ------------------------------------------------------- */
  const excelColumns = [
    { label: "Student Name", value: "fullname" }, // Top level data
    { label: "Email", value: "email" }, // Custom format
    {
      label: "Mobile No.",
      value: (row) => (row.phone ? row.phone || "" : "-"),
    },
    {
      label: "Enrolled Course",
      value: (row) => (row.course ? row.course || "" : "-"),
    },
    {
      label: "Enrolled Date",
      value: (row) => (row.enroll_date ? row.enroll_date || "" : "-"),
    },
    {
      label: "Student Status",
      value: (row) =>
        row.status.status_name ? row.status.status_name || "" : "-",
    },
  ];
  /* --------------------------------------------------------------Excel sheet column details ends ------------------------------------------------------- */
  return (
    <div>
      <Modal
        open={modalOpen}
        setModalOpen={setModalOpen}
        handleModal={handleModal}
        isModalLoading={isModalLoading}
        ModalLoader={ModalLoader}
      />
      <DeleteModal
        open={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        handleDeleteModal={handleDeleteModal}
        modalTitle={"Are you sure you want to delete this student?"}
      />
      <AdminHeader title={"Students"} />
      <div className={styles.tablePage}>
        <div>
          <div className={styles.firstFilterContainer}>
            <div className={styles.filterWrapper}>
              <div className={styles.dropdownFilter}>
                <select
                  name="enrolledCourse"
                  id="enrolledCourse"
                  onChange={(e) => setCourseValue(e.target.value)}
                >
                  <option className={styles.hide} value="">
                    Enrolled Course
                  </option>
                  {courseData?.map((course) => (
                    <option value={course?.id}>{course?.title}</option>
                  ))}
                </select>
              </div>
            </div>

            <button
              className={styles.exportButton}
              onClick={() => {
                excelGenerator(`studentsGet`, excelColumns, "students");
              }}
            >
              <svg
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6154 14.8462H19.2692C21.9135 14.8462 24.0769 13.8264 24.0769 11.2115C24.0769 8.59663 21.5288 7.67933 19.4615 7.57692C19.0341 3.4875 16.0481 1 12.5385 1C9.22115 1 7.08462 3.20144 6.38462 5.38462C3.5 5.65865 1 7.10962 1 10.1154C1 13.1212 3.59615 14.8462 6.76923 14.8462H9.46154M9.46154 17.9279L12.5385 21L15.6154 17.9279M12.5385 9.46154V20.2322"
                  stroke="#4B6CB7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Export Excel
            </button>
          </div>
          <div className={styles.secondFilterContainer}>
            <div className={styles.entriesFilter}>
              <label htmlFor="entries">Entries:</label>
              <select
                name="entries"
                id="entries"
                onChange={(e) => setEntriesValue(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </div>

            <div className={styles.searchDiv}>
              <input
                className={styles.searchInput}
                placeholder="Search"
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <img src={search} />
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loader type={"component"} />
        ) : (
          <Table
            columns={columns}
            data={studentsData}
            entriesValue={entriesValue}
            currentPage={currentPage}
            setCurrentPage={handleCurrentPage}
            totalPages={totalPages && totalPages}
          />
        )}
      </div>
    </div>
  );
};

export default Students;
