//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from "react";
import styles from "../../assets/styles/HomePage.module.css";
import HomeBG from "../../assets/images/HomeBG.png";
import { useNavigate } from "react-router-dom";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Section1({ setShowModal }) {
  const loginCheck = localStorage.getItem("loginUser");
  const navigation = useNavigate();

  return (
    <div className={styles.section1}>
      <div className={styles.textDiv}>
        <div class={styles.hwrap}>
          <div class={styles.hmove}>
            <div class={styles.hslide}>
              <p className={styles.section1TitleText}>FLEXIBLE LEARNING</p>
              <p className={styles.section1SubText}>Self paced online & offline Classrooms</p>
            </div>
            <div class={styles.hslide}>
              <p className={styles.section1TitleText}>ACQUIRE KNOWLEDGE</p>
              <p className={styles.section1SubText}>Excel your career in IT</p>
            </div>
            <div class={styles.hslide}>
              <p className={styles.section1TitleText}>INCREASE EARNING</p>
              <p className={styles.section1SubText}>Get ahead with healthcare courses</p>
            </div>
            <div class={styles.hslide}>
              <p className={styles.section1TitleText}>Job Placement Support</p>
              <p className={styles.section1SubText}>After Training</p>
            </div>
          </div>
        </div>
        {loginCheck ? (
          <>
            <button
              className={styles.registerBtn + " " + styles.addBottomMargin}
              id="registerBtn"
              onClick={() => {
                navigation("/inquiry");
              }}
            >
              <div>
                <p className={styles.registerBtnText}>Inquiry Now</p>
              </div>
            </button>
          </>
        ) : (
          <>
            <button
              className={styles.registerBtn + " " + styles.addBottomMargin}
              id="registerBtn"
              onClick={() => setShowModal("Register")}
            >
              <div>
                <p className={styles.registerBtnText}>Register Now</p>
              </div>
            </button>
          </>
        )}
      </div>
      <div className={styles.imgDiv}>
        <img src={HomeBG} alt="Home Backgroud Image" className={styles.homeBgImg} />
      </div>
    </div>
  );
}

export default Section1;
