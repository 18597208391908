import React from 'react';
import styles from '../assets/styles/Course.module.css'
import Section1 from './Course/Section1';
function Course() {
    return (
        <div className={styles.sectionContainer}> 
            <Section1 />
        </div>
    );
}

export default Course;