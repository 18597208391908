import Request from "axios";
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import stylesn from "../../assets/styles/Course.module.css";
import styles from '../../assets/styles/Timings.module.css';
import { BASE_URL } from '../../components/baseURL';
import searchIcon from "../../assets/images/Search.svg";

function Section2() {
    const [changePage, setChangePage] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [dataOfTiming, setDataOfTiming] = useState()
    const [numOfDataTiming, setNumOfDataTiming] = useState(10)
    const [itemsPerPage, setItemsPerPage] = useState(numOfDataTiming);
    const [pageCount, setPageCount] = useState(dataOfTiming?.data?.total_pages)
    const columns = [
        {
            name: "Course Name",
            selector: (row) => row.course.title ?? "-",
            sortable: true,
            width: "678px",
            wrap: true,
            style: {
                borderLeft: '1px solid #BBBBBB',
            },
        },
        {
            name: "Duration",
            selector: (row) => row.course.duration ?? "-",
            sortable: true,
            // minWidth: "160px",
        },
        {
            name: "Start Date",
            selector: (row) => row.StartDate ?? "-",
            sortable: true,
            // width: "243px",
        },
        {
            name: "End Date",
            selector: (row) => row.EndDate ?? "-",
            sortable: true,
            wrap: true,
            // width: "243px",
        },
        {
            name: "Timings",
            selector: (row) => row.Timings ?? "-",
            sortable: true,
            wrap: true,
            width: "246px",
        },
        {
            name: "Days",
            selector: (row) => row.Days ?? "-",
            sortable: true,
            // width: "150px",
        },
    ];

    const tableCustomStyles = {
        headRow: {
            style: {
                color: "#232323",
                backgroundColor: "#FFF7EF",
                fontSize: "24px",
                fontWeight: "500",
                height: "77px",
            },
        },
        rows: {
            style: {
                fontSize: "20px",
                fontWeight: "400",
                height: "57px",
                color: "#676767",
            },
        },
        headCells: {
            style: {
                border: '1px solid #BBBBBB',
                borderLeft: 0,
                "&:first-child": {
                    borderLeft: '1px solid #BBBBBB',
                },

            },
        },
        cells: {
            style: {
                border: '1px solid #BBBBBB',
                borderTop: 0,
                borderLeft: 0
            },
        },
    };  
    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        setChangePage(!changePage)
    };

    useEffect(() => {
        Request.get(`${BASE_URL}timingGet?search=${search}&page=${currentPage}&page_size=${numOfDataTiming}`)
            .then((res) => {
                setPageCount(res?.data?.total_pages)
                setDataOfTiming(res)
            })
            .catch((err) => {
                alert("Error fetching clients", err);

            })


    }, [currentPage, setCurrentPage, numOfDataTiming, itemsPerPage, changePage, search]);



    return (
        <div className={styles.Section2}>
            <div className={styles.searchAndEntities}>
                <select value={numOfDataTiming} className={styles.entites} onChange={(e) =>{ setNumOfDataTiming(e.target.value);  setCurrentPage(1)}}>
                    <option value={100} >100</option>
                    <option value={50} >50</option>
                    <option value={20} >20</option>
                    <option value={10} >10</option>
                </select>
                <div className={styles.searchDiv}>
                    <input
                        className={styles.searchInput}
                        placeholder="Search"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSearch(e.target.value)
                            }
                        }}
                    />
                    <img src={searchIcon} />
                </div>
            </div>
            <div className={styles.tableContainer}>
                <DataTable
                    columns={columns}
                    data={dataOfTiming?.data?.results}
                    customStyles={tableCustomStyles}
                />
                <div className={styles.paginationDiv}>


                    {dataOfTiming?.data?.results?.length > 0 && (

                        <ReactPaginate
                            className={stylesn.pagemain}
                            activeClassName={stylesn.activeclass}
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            pageCount={pageCount}
                            previousLabel="<"
                            marginPagesDisplayed={1}
                            renderOnZeroPageCount={null}
                            pageLinkClassName={stylesn.innerNum}
                            previousLinkClassName={stylesn.innerNum}
                            nextLinkClassName={stylesn.innerNum}
                        />
                    )}
                </div>
                <p className={styles.weekEndText}>* For Weekend Batch Schedule Contact Us</p>
            </div>
        </div>
    )
}

export default Section2 