//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React ,{useState,useEffect} from 'react';
import styles from '../assets/styles/Inquiry.module.css'
import Section2 from '../Pages/Inquiry/Section2';


//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//


function Inquiry() {
    
    return (
        <div className={styles.sectionContainer}>
            

            <div className={styles.section1}> 
                <p className={styles.titleText}>INQUIRY</p>   
            </div>   

            <Section2 />
        </div>
    );
}

export default Inquiry;