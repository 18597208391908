import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/Admin/table.module.css";
import Pagination from "./Pagination";

const tableCustomStyles = {
  headRow: {
    style: {
      color: "#232323",
      backgroundColor: "#F5F5F5",
      borderRadius: "0",
      fontSize: "18px",
    },
  },
  rows: {
    style: {
      padding: "10px 0",
      fontSize: "18px",
      backgroundColor: "#FFFFFF!important",
      "&:nth-child(2n)": {
        backgroundColor: "#F3F7FF!important",
        color: "#232323",
      },
    },
  },
};

const Table = ({
  columns,
  data,
  entriesValue = 25,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(entriesValue);

  const [startIndex, setStartIndex] = useState(
    (currentPage - 1) * itemsPerPage
  );
  const [endIndex, setEndIndex] = useState(startIndex + itemsPerPage);

  useEffect(() => {
    setStartIndex((currentPage - 1) * itemsPerPage);
    setEndIndex(startIndex + itemsPerPage);
    setItemsPerPage(entriesValue);
  }, [entriesValue, currentPage]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
      }
    };

    return (
      <button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  return (
    <>
      <div className={styles.tableContainer}>
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          contextActions={contextActions}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
          striped={true}
          customStyles={tableCustomStyles}
          sortIcon={
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.945 13.1711V0H2.87357V13.1711L0.7575 11.055L0 11.8125L3.03054 14.843C3.131 14.9435 3.26723 14.9999 3.40929 14.9999C3.55134 14.9999 3.68757 14.9435 3.78804 14.843L6.81857 11.8125L6.06107 11.055L3.945 13.1711ZM14.3186 3.1875L11.288 0.156964C11.1876 0.0565333 11.0513 0.000114441 10.9093 0.000114441C10.7672 0.000114441 10.631 0.0565333 10.5305 0.156964L7.5 3.1875L8.2575 3.945L10.3736 1.82893V15H11.445V1.82893L13.5611 3.945L14.3186 3.1875Z"
                fill="#959595"
              />
            </svg>
          }
        />
        {data?.length > 0 && (
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            responsive={true}
            count={data?.length}
          />
        )}
      </div>
    </>
  );
};

export default Table;
