//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

import React, { useEffect, useState } from "react";
import styles from "../assets/styles/CourseDetail.module.css";
import courseDetailFirst from "../assets/images/CourseFrontEndImg.png";
import border from "../assets/images/border.png";
import noProfile from "../assets/images/noProfile.png";
import fiveStar from "../assets/images/fiveStar.png";
import checkMark from "../assets/images/checkMark.png";
import courseLevel from "../assets/images/courseLevel.png";
import courseDuration from "../assets/images/courseDuration.png";
import courseLectures from "../assets/images/courseLectures.png";
import courseQuizes from "../assets/images/courseQuizes.png";
import courseLanguage from "../assets/images/courseLangauage.png";
import courseAssessment from "../assets/images/courseAssessment.png";
import courseCertificate from "../assets/images/courseCertificate.png";
import Section3 from "./Coursecontent/Section3";
import LockContentModal from "./Coursecontent/LockContentModal";
import Section4 from "./Coursecontent/Section4";
import { useLocation } from "react-router";
import { BASE_URL } from "../components/baseURL";
import Request from "axios";
import emptyStar from "../assets/images/emptyStar.png";
import star from "../assets/images/star.png";
import { useNavigate, useParams } from "react-router-dom";
import useLoader from "../hooks/useLoader";
let media = "media";

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function CourseDetail() {
  const params = useParams();
  const location = useLocation();
  const id = location?.state?.data || params?.id;
  const [data, setData] = useState(" ");
  const [courseOverview, setCourseOverview] = useState();
  const [courseWhatLearn, setCourseWhatLearn] = useState("");
  const { isLoading, setLoader, Loader } = useLoader();
  const starRating = location?.state?.rating
  const navigation = useNavigate();
  useEffect(() => {
    setLoader(true);
    Request.get(`${BASE_URL}courseGet?id=${id}`)
      .then((res) => {
        setData(res.data);
        var whatLearn = res?.data?.whatlearn;
        whatLearn = whatLearn?.split("~");
        whatLearn[0] = whatLearn[0];
        whatLearn[whatLearn.length - 1] = whatLearn[whatLearn.length - 1].substring(0);
        whatLearn.forEach((x, i) => {
          whatLearn[i] = whatLearn[i].includes('"')
            ? whatLearn[i].replaceAll('"', "").trim()
            : whatLearn[i].replaceAll("'", "").trim();
        });
        setCourseWhatLearn(whatLearn);

        // for overview
        var overviewNew = res?.data?.overview;
        overviewNew = overviewNew?.split("~");
        overviewNew[0] = overviewNew[0];
        overviewNew[overviewNew.length - 1] = overviewNew[overviewNew.length - 1].substring(0);
        overviewNew.forEach((x, i) => {
          overviewNew[i] = overviewNew[i].includes('"')
            ? overviewNew[i].replaceAll('"', "").trim()
            : overviewNew[i].replaceAll("'", "").trim();
        });
        setCourseOverview(overviewNew);

        // let subTemp=(res?.data?.submodule?.split("*"))
        // console.log("Sub",subTemp)
        // setCourseOverview(subTemp)
      })
      .catch((err) => {
        alert("Error fetching category", err);
        console.log("Error fetching category", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);
  return (
    <>
      <div className={styles.detailContainer}>
        {isLoading ? (
          <Loader type="component" />
        ) : (
          <>
            <div className={styles.firstSectionMain}>
              <div className={styles.firstSection}>
                <div className={styles.firstLeftSection}>
                  <div className={styles.courseHeader}>
                    <label>Home</label>
                    <div className={styles.courseDot}></div>
                    <label>Courses</label>
                    <div className={styles.courseDot}></div>
                    <label>{data?.title}</label>
                  </div>
                  <div className={styles.firstRightSectionMobile}>
                    <img src={BASE_URL + data.image} alt="" className={styles.rightMainImg}></img>
                  </div>
                  <div className={styles.leftSection}>
                    <div className={styles.courseType}>
                      <span>{data?.category}</span>
                    </div>
                    <label className={styles.heading}>{data?.title}</label>
                    <label className={styles.description}>{data?.detail}</label>
                    <div className={styles.profileName}>
                      <img src={noProfile} alt=""></img>
                      <label>{data?.instructor}</label>
                    </div>
                    <div className={styles.starAndCount}>
                      {/* <img src={fiveStar} alt=""></img> */}
                      <div>
                        <img
                          src={starRating > 0 ? star : emptyStar}
                          alt="Rating Icon"
                          className={styles.ratingIconAll}
                        />
                        <img
                          src={starRating > 1 ? star : emptyStar}
                          alt="Rating Icon"
                          className={styles.ratingIconAll}
                        />
                        <img
                          src={starRating > 2 ? star : emptyStar}
                          alt="Rating Icon"
                          className={styles.ratingIconAll}
                        />
                        <img
                          src={starRating > 3 ? star : emptyStar}
                          alt="Rating Icon"
                          className={styles.ratingIconAll}
                        />
                        <img
                          src={starRating > 4 ? star : emptyStar}
                          alt="Rating Icon"
                          className={styles.ratingIconAll}
                        />
                      </div>
                      <div className={styles.middleLine}></div>
                      <label>
                        {Math.floor(Math.random() * (30 - 20 + 1)) + 20} enrolled Student
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.firstRightSection}>
                  <img src={BASE_URL + data.image} alt="" className={styles.rightMainImg}></img>
                </div>
              </div>
            </div>
            <div className={styles.secondSection}>
              <div className={styles.secondMaindiv}>
                <div className={styles.secondLeftSection}>
                  <span>Course Overview</span>
                  <div className={styles.overview}>
                    {courseOverview &&
                      courseOverview?.map((val, i) => {
                        return <label key={String(i)}>{val}</label>;
                      })}
                  </div>
                  <div className={styles.learningContainer}>
                    <label>What you'll learn in this course</label>
                    {courseWhatLearn &&
                      courseWhatLearn?.map((vals, i) => {
                        return (
                          <div key={String(i)} className={styles.arrowText}>
                            <img src={checkMark} alt=""></img>
                            <label>{vals}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className={styles.secondRightSection}>
                  <div className={styles.levelMain}>
                    <img src={courseLevel} alt=""></img>
                    <div className={styles.levelContent}>
                      <label>Course Level</label>
                      <label>{data?.level}</label>
                    </div>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.levelMain}>
                    <img src={courseDuration} alt=""></img>
                    <div className={styles.levelContent}>
                      <label>Course Duration</label>
                      <label>{data?.duration}</label>
                    </div>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.levelMain}>
                    <img src={courseLectures} alt=""></img>
                    <div className={styles.levelContent}>
                      <label>Lectures</label>
                      <label>{data?.lectures}</label>
                    </div>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.levelMain}>
                    <img src={courseQuizes} alt=""></img>
                    <div className={styles.levelContent}>
                      <label>Quizzes</label>
                      <label>{data?.quizzes}</label>
                    </div>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.levelMain}>
                    <img src={courseLanguage} alt=""></img>
                    <div className={styles.levelContent}>
                      <label>Language</label>
                      <label>{data?.language}</label>
                    </div>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.levelMain}>
                    <img src={courseAssessment} alt=""></img>
                    <div className={styles.levelContent}>
                      <label>Assessments</label>
                      <label>{data?.assessments}</label>
                    </div>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.levelMain}>
                    <img src={courseCertificate} alt=""></img>
                    <div className={styles.levelContent}>
                      <label>Certificate</label>
                      <label>{data?.certificates}</label>
                    </div>
                  </div>
                  <div className={styles.enrollButton}>
                    <button
                      className={styles.enrollBtn}
                      onClick={() => {
                        navigation("/inquiry");
                      }}
                    >
                      <div>
                        <p className={styles.enrollBtnText}>Inquiry Now</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={styles.thirdSection}>
          <Section3 id={id} />
        </div>
        <div className={styles.section4}>
          <Section4 />
        </div>
      </div>
    </>
  );
}

export default CourseDetail;
