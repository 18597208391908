//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from 'react';
import styles from '../../assets/styles/AboutUs.module.css'
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Section2() {
    return (
        <div className={styles.section2}>
            <div className={styles.missionDiv}>
                <p className={styles.titleText}>
                    Our Mission
                </p>
                <p className={styles.subText}>
                    Our Mission is to Provide a World‑class Education.
                </p>
            </div>
            <div className={styles.detailDiv}>
                <p className={styles.text}>
                    We offer high quality advanced training and are unswerving to providing 
                    the best training facilities to our students. Our highly qualified instructors 
                    are professionals in their field of proficiency. Our instructors strongly promote 
                    competencies and job readiness. Our instructors are dedicated to teach effective 
                    skills in communication, leadership, professionalism and ethics. Our goal is to 
                    match the speed of technology by providing an up-to-date education on the most 
                    advanced equipments and technologies available. Our efforts will remain focused 
                    achieve uncompromising success in imparting professional skills to our candidates 
                    and to ensure that they obtain excellent career.
                </p>
            </div>
        </div>
    );
}

export default Section2;