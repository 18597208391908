import React from 'react'
import styles from '../assets/styles/Timings.module.css'
import Section2 from './Timings/Section2'

function Timings() {
    return (
        <div className={styles.sectionContainer}>


            <div className={styles.section1}>
                <p className={styles.titleText}>Timings</p>
            </div>

            <Section2 />
        </div>
    )
}

export default Timings