import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";

const paginate = () => {};
const Pagination = ({
  onPageChange,
  currentPage,
  itemsPerPage,
  totalPages,
  count
}) => {
  const [totalItems, setTotalItems] = useState(itemsPerPage * totalPages);
  const [start, setStart] = useState((currentPage - 1) * itemsPerPage + 1);
  const [end, setEnd] = useState( Math.min(currentPage * itemsPerPage, totalItems));

  useEffect(() => {
    setTotalItems(itemsPerPage * totalPages);
    setStart((currentPage - 1) * itemsPerPage + 1);
    setEnd(Math.min(currentPage * count, totalItems));

    

    console.log(itemsPerPage,totalPages,currentPage)
  }, [currentPage, itemsPerPage]);

  // e.g. "21-30 of 193 items"
  // console.log(start + "-" + end + " of " + totalItems + " items");

  return (
    <div className="pageContainer">
      <div className="pageDetails">
        {"Showing " + start + " to " + end + " of " + totalItems + " entries"}
      </div>
      <ReactPaginate
        activeClassName="pagination-active" // The class name for the active page
        breakClassName="pagination-break" // The class name for the break ('...')
        containerClassName="pagination-container" // The class name for the pagination container
        initialPage={currentPage - 1} // The initial page
        marginPagesDisplayed={1} // The number of pages displayed at the margins
        nextClassName="pagination-next-prev" // The class name for the next button
        onPageChange={(e) => onPageChange(e)} // The function to call when the page changes
        pageCount={totalPages} // The total number of pages
        pageRangeDisplayed={2} // The range of pages displayed
        pageClassName="pagination-page" // The class name for the page
        previousClassName="pagination-next-prev" // The class name for the previous button
        disableInitialCallback={true}
      />
    </div>
  );
};

export default Pagination;
