//--------------------------------------------------------------- All Imports Starts -------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "../../../assets/styles/Admin/Courses.module.css";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { useDispatch } from "react-redux";
import useLoader from "../../../hooks/useLoader";
import { BASE_URL } from "../../../components/baseURL";
import Request from "axios";
import { useParams } from "react-router-dom";
//--------------------------------------------------------------- All Imports Ends -------------------------------------------------------------------//

const BasicDetails = ({ handleFormStep, isEdit }) => {
  //---------------------------------------------  Initializing states, variables and hooks starts ---------------------------------------------------//
  const params = useParams();
  const id = params?.id;
  const { isLoading, setLoader, Loader } = useLoader();
  const [instructorData, setInstructorData] = useState();
  const [categoryData, setCategoryData] = useState();
  const dispatch = useDispatch();
  const [courseData, setCourseData] = useState();
  const [imageUploaded, setImageUploaded] = useState(false);
  //---------------------------------------------  Initializing states, variables and hooks ends ---------------------------------------------------//

  //-------------------------------------------- Form Validation Schema Initialization Starts ---------------------------------------------------------------//
  const validationSchema = Yup.object().shape({
    title: Yup.string().max(100).required("Course title is required."),
    instructor: Yup.string().required("Instructor is required."),
    category: Yup.string().required("Category is required."),
    detail: Yup.string().max(500).required("Course description is required."),
    overview: Yup.string().max(5000).required("Course overview is required."),
    whatlearn: Yup.string()
      .max(2000)
      .required("What you'll learn is required."),
    image: Yup.string().required("Image is required."),
    assessments: Yup.string().required("Please select one option."),
    certificates: Yup.string().max(50).required("Please select one option."),
    level: Yup.string().required("Please select one option."),
    duration: Yup.string().max(50).required("Course duration is required."),
    quizzes: Yup.string().max(50).required("Quizzes are required."),
    lectures: Yup.string().max(50).required("Lectures are required."),
    language: Yup.string(),
  });
  //-------------------------------------------- Form Validation Schema Initialization Ends ---------------------------------------------------------------//

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = (values) => {
    const url = isEdit ? `${BASE_URL}course/${id}/` : `${BASE_URL}coursePost`;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    if (isEdit) {
      if (typeof values.image === "string") {
        delete values.image;
      }
      Request.patch(url, values, {
        headers,
      })
        .then((res) => {
          dispatch({ type: "ADDED_COURSE_ID", payload: res.data.id });
          console.log(res.data.id);
          handleFormStep(2);
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {});
    } else {
      Request.post(url, values, {
        headers,
      })
        .then((res) => {
          dispatch({ type: "ADDED_COURSE_ID", payload: res.data.id });
          console.log(res.data.id);
          handleFormStep(2);
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {});
    }
  };
  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//

  //------------------------------------------------------- useEffect to fetch Instuctor Data Starts ---------------------------------------------------//
  useEffect(() => {
    Request.get(`${BASE_URL}/instructorGet`)
      .then((res) => {
        setInstructorData(res.data);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {});
  }, []);
  //------------------------------------------------------- useEffect to fetch Instuctor Data Ends ---------------------------------------------------//

  //------------------------------------------------------- useEffect to fetch Category Data Starts ---------------------------------------------------//
  useEffect(() => {
    Request.get(`${BASE_URL}/categoryGet`)
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {});
  }, []);
  //------------------------------------------------------- useEffect to fetch Category Data Ends ---------------------------------------------------//

  //------------------------------------------------ useEffect to fetch Course Data in Case of Edit Starts -----------------------------------------//
  useEffect(() => {
    if (id) {
      setLoader(true);
      Request.get(`${BASE_URL}/courseGet?id=${id}`)
        .then((res) => {
          setCourseData(res.data);
          setImageUploaded(`${BASE_URL}${res.data.image}`);
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [isEdit]);
  //------------------------------------------------ useEffect to fetch Course Data in Case of Edit Ends -----------------------------------------//
  return (
    <div>
      <div>
        {isLoading ? (
          <Loader type={"component"} />
        ) : (
          <Formik
            initialValues={{
              title: isEdit ? courseData?.title : "",
              instructor: isEdit ? courseData?.instructor_id : "",
              detail: isEdit ? courseData?.detail : "",
              overview: isEdit ? courseData?.overview : "",
              whatlearn: isEdit ? courseData?.whatlearn : "",
              image: isEdit ? courseData?.image : "",
              assessments: isEdit ? courseData?.assessments : "",
              certificates: isEdit ? courseData?.certificates : "",
              level: isEdit ? courseData?.level : "",
              duration: isEdit ? courseData?.duration : "",
              quizzes: isEdit ? courseData?.quizzes : "",
              lectures: isEdit ? courseData?.lectures : "",
              language: "English",
              category: isEdit ? courseData?.category_id : "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form className={styles.basicDetailsForm}>
                <div className={styles.threeInputFields}>
                  <div className={styles.fieldDiv}>
                    <label>Course Title</label>
                    <Field
                      type="text"
                      name="title"
                      placeHolder="Course Title"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className={styles.fieldDiv}>
                    <label>Instructor</label>
                    <Field
                      as="select"
                      name="instructor"
                      disabled={!instructorData}
                    >
                      <option value="select-instructor" className="hide">
                        Select Instructor
                      </option>
                      {instructorData?.map((instructor) => (
                        <option value={instructor.id}>
                          {instructor.fullname}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="instructor"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className={styles.fieldDiv}>
                    <label>Category</label>
                    <Field as="select" name="category" disabled={!categoryData}>
                      <option value="select-instructor" className="hide">
                        Select Category
                      </option>
                      {categoryData?.map((category) => (
                        <option value={category.id}>{category.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="category"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className={styles.formFields}>
                  <div className={styles.fieldDiv}>
                    <label>Course Description</label>
                    <Field
                      as="textarea"
                      name="detail"
                      placeHolder="Description"
                      rows="5"
                    />
                    <ErrorMessage
                      name="detail"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className={styles.fieldDiv}>
                    <label>Course Overview</label>
                    <Field
                      as="textarea"
                      name="overview"
                      placeHolder="Course Overview"
                      rows="5"
                    />
                    <ErrorMessage
                      name="overview"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className={styles.fieldDiv}>
                    <label>What you'll learn in this course</label>
                    <Field
                      as="textarea"
                      name="whatlearn"
                      placeHolder="Description"
                      rows="5"
                    />
                    <ErrorMessage
                      name="whatlearn"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className={styles.fieldDiv}>
                    <label>Image</label>
                    <label className={styles.imageUploadContainer}>
                      <Field
                        type="file"
                        name="image"
                        accept="image/jpeg, image/png"
                        value={undefined}
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0]);
                          setImageUploaded(
                            URL.createObjectURL(event.target.files[0])
                          );
                        }}
                      />
                      {!imageUploaded ? (
                        <>
                          <img src={uploadIcon} />
                          <span className={styles.imgTypeFormat}>
                            .jpg, .png only
                          </span>
                          <p className={styles.imgUploadMessage}>
                            Drag & Drop or Browse <span>Browse</span>
                          </p>
                        </>
                      ) : (
                        <img
                          className={styles.previewImage}
                          src={imageUploaded}
                        />
                      )}
                    </label>
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                <div className={styles.radioDivContainer}>
                  <div className={styles.radioDiv}>
                    <label>Assessments</label>
                    <div className={styles.radioOptions}>
                      <label>
                        <Field type="radio" name="assessments" value="YES" />
                        Yes
                      </label>
                      <label>
                        <Field type="radio" name="assessments" value="NO" />
                        No
                      </label>
                    </div>
                    <ErrorMessage
                      name="assessments"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className={styles.radioDiv}>
                    <label>Certificate</label>
                    <div className={styles.radioOptions}>
                      <label>
                        <Field type="radio" name="certificates" value="YES" />
                        Yes
                      </label>
                      <label>
                        <Field type="radio" name="certificates" value="NO" />
                        No
                      </label>
                    </div>
                    <ErrorMessage
                      name="certificates"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className={styles.radioDiv}>
                    <label>Course Level</label>
                    <div className={styles.radioOptions}>
                      <label>
                        <Field type="radio" name="level" value="Beginner" />
                        Beginner
                      </label>
                      <label>
                        <Field type="radio" name="level" value="Intermediate" />
                        Intermediate
                      </label>
                      <label>
                        <Field type="radio" name="level" value="Expert" />
                        Expert
                      </label>
                    </div>
                    <ErrorMessage
                      name="level"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                <div className={styles.formFields}>
                  <div className={styles.fieldDiv}>
                    <label>Course Duration</label>
                    <Field
                      type="text"
                      name="duration"
                      placeHolder="Course Duration"
                    />
                    <ErrorMessage
                      name="duration"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className={styles.fieldDiv}>
                    <label>Quizzes</label>
                    <Field type="text" name="quizzes" placeHolder="Quizzes" />
                    <ErrorMessage
                      name="quizzes"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className={styles.fieldDiv}>
                    <label>Lectures</label>
                    <Field type="text" name="lectures" placeHolder="Lectures" />
                    <ErrorMessage
                      name="lectures"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className={styles.fieldDiv}>
                    <label>Language</label>
                    <Field
                      type="text"
                      name="language"
                      placeHolder="Language"
                      value="English"
                      disabled
                    />
                  </div>
                </div>

                <div className={styles.nextButtonDiv}>
                  <button className={styles.nextButton} type="submit">
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default BasicDetails;
