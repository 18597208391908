// -------------------------------------------------------------------- All Imports Starts ------------------------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import Table from "../../components/Admin/Table";
import AdminHeader from "../../components/Admin/AdminHeader";
import styles from "../../assets/styles/Admin/table.module.css";
import search from "../../assets/images/Search.svg";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import useLoader from "../../hooks/useLoader";
import { excelGenerator } from "../../utils/excelGenerator";
// -------------------------------------------------------------------- All Imports Ends ------------------------------------------------------------------------------------------//

export const Inquiry = () => {
  // -------------------------------------------------------------------- States, Variable Initialization Starts ------------------------------------------------------------------//
  const [dateValue, setDateValue] = useState("");
  const [courseValue, setCourseValue] = useState("");
  const [entriesValue, setEntriesValue] = useState("25");
  const [inquiryData, setInquiryData] = useState();
  const [searchInput, setSearchInput] = useState("");
  const { isLoading, setLoader, Loader } = useLoader();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(25);
  const [courseData, setCourseData] = useState();
  const columns = [
    {
      name: "Date",
      selector: (row) => row?.created_at ?? "-",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.fullname ?? "-",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email ?? "-",
      sortable: true,
    },
    {
      name: "Mobile No.",
      selector: (row) => row?.phone ?? "-",
      sortable: true,
    },
    {
      name: "Interested Course",
      selector: (row) => row?.interestedcourse?.title ?? "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Country",
      selector: (row) => row.country ?? "-",
      sortable: true,
    },
  ];
  const excelColumns = [
    { label: "Date", value: "created_at" },
    { label: "Name", value: "fullname" }, // Top level data
    { label: "Email", value: "email" }, // Custom format
    {
      label: "Mobile No.",
      value: (row) => (row.phone ? row.phone || "" : "-"),
    },
    {
      label: "Interested Course",
      value: (row) =>
        row?.interestedcourse?.title ? row?.interestedcourse?.title || "" : "-",
    },
    {
      label: "Country",
      value: (row) => (row.country ? row.country || "" : "-"),
    },
  ];
  // -------------------------------------------------------------------- States, Variable Initialization Ends ------------------------------------------------------------------//

  // -------------------------------------------------------------------- Function to Set Current Page Starts ------------------------------------------------------------------//
  const handleCurrentPage = (e) => {
    setCurrentPage(e);
  };
  // -------------------------------------------------------------------- Function to Set Current Page Ends ------------------------------------------------------------------//

  // ------------------------------------------------------- useEffect to Fetch Inquiry Data with Filters Starts ----------------------------------------------------//
  useEffect(() => {
    setLoader(true);
    let formattedDate;
    if (dateValue != "Invalid Date" && dateValue) {
      formattedDate = new Date(dateValue)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      setDateValue(formattedDate);
    }

    Request.get(
      `${BASE_URL}inquiryGet?date=${
        dateValue ? formattedDate : ""
      }&course=${courseValue}&page=${currentPage}&items=${entriesValue}&search=${searchInput}`
    )
      .then((res) => {
        setInquiryData(res?.data?.data);
        setTotalPages(res?.data?.total_pages);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [dateValue, courseValue, searchInput, entriesValue, currentPage]);
  // ------------------------------------------------------- useEffect to Fetch Inquiry Data with Filters Ends ----------------------------------------------------//

  // ------------------------------------------------------- useEffect to Fetch Courses Data Starts ----------------------------------------------------//
  useEffect(() => {
    setLoader(true);
    Request.get(`${BASE_URL}/courseGet`)
      .then((res) => {
        setCourseData(res.data);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);
  // ------------------------------------------------------- useEffect to Fetch Courses Data Starts ----------------------------------------------------//

  return (
    <div>
      <AdminHeader title={"Inquiry"} />
      <div className={styles.tablePage}>
        <div>
          <div className={styles.firstFilterContainer}>
            <div className={styles.filterWrapper}>
              <div className={styles.dateFilter}>
                <DatePicker
                  onChange={setDateValue}
                  value={dateValue}
                  className={styles.datePicker}
                  format="dd-MM-yy"
                  yearPlaceholder="YYYY"
                  monthPlaceholder="MM"
                  dayPlaceholder="DD"
                />
              </div>

              <div className={styles.dropdownFilter}>
                <select
                  name="enrolledCourse"
                  id="enrolledCourse"
                  onChange={(e) => setCourseValue(e.target.value)}
                >
                  {courseData?.map((course) => (
                    <option value={course?.id}>{course?.title}</option>
                  ))}
                </select>
              </div>
            </div>

            <button
              className={styles.exportButton}
              onClick={() => {
                excelGenerator(`inquiryGet`, excelColumns, "inquiry");
              }}
            >
              <svg
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6154 14.8462H19.2692C21.9135 14.8462 24.0769 13.8264 24.0769 11.2115C24.0769 8.59663 21.5288 7.67933 19.4615 7.57692C19.0341 3.4875 16.0481 1 12.5385 1C9.22115 1 7.08462 3.20144 6.38462 5.38462C3.5 5.65865 1 7.10962 1 10.1154C1 13.1212 3.59615 14.8462 6.76923 14.8462H9.46154M9.46154 17.9279L12.5385 21L15.6154 17.9279M12.5385 9.46154V20.2322"
                  stroke="#4B6CB7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Export Excel
            </button>
          </div>
          <div className={styles.secondFilterContainer}>
            <div className={styles.entriesFilter}>
              <label htmlFor="entries">Entries:</label>
              <select
                name="entries"
                id="entries"
                onChange={(e) => setEntriesValue(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </div>

            <div className={styles.searchDiv}>
              <input
                className={styles.searchInput}
                placeholder="Search"
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <img src={search} />
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader type={"component"} />
        ) : (
          <Table
            columns={columns}
            data={inquiryData}
            entriesValue={entriesValue}
            currentPage={currentPage}
            setCurrentPage={handleCurrentPage}
            totalPages={totalPages && totalPages}
          />
        )}
      </div>
    </div>
  );
};
