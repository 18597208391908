//--------------------------------------------------------------- All Imports Starts -------------------------------------------------------------------//
import Request from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddIcon from "../../../assets/images/addBlueIcon.svg";
import RemoveIcon from "../../../assets/images/removeBlueIcon.svg";
import styles from "../../../assets/styles/Admin/Courses.module.css";
import useLoader from "../../../hooks/useLoader";
import { BASE_URL } from "../../baseURL";
//--------------------------------------------------------------- All Imports Ends -------------------------------------------------------------------//

const PrimaryModule = ({ handleFormStep, isEdit }) => {
  //---------------------------------------------  Initializing states, variables and hooks starts ---------------------------------------------------//
  const params = useParams();
  let courseId = useSelector((state) => state.CoursesReducers.addedCourseId);
  const id = params?.id || courseId;
  const { setLoader } = useLoader();
  const [moduleData, setModuleData] = useState([
    {
      id: 1,
      module: "",
      course: id,
    },
  ]);
  //---------------------------------------------  Initializing states, variables and hooks ends ---------------------------------------------------//

  //---------------------------------------------  Function to add dynamic field starts ---------------------------------------------------//
  const handleAddField = () => {
    const newFields = [
      ...moduleData,
      { id: moduleData.length + 1, module: "", course: id },
    ];
    console.log(newFields);
    setModuleData(newFields);
  };
  //---------------------------------------------  Function to add dynamic field ends ---------------------------------------------------//

  //---------------------------------------------  Function to add dynamic field ends ---------------------------------------------------//
  const handleRemoveField = () => {
    const updatedFields = [...moduleData];
    updatedFields.pop();
    setModuleData(updatedFields);
  };
  //---------------------------------------------  Function to remove dynamic field ends ---------------------------------------------------//

  //---------------------------------------------  Function to check empty field starts ---------------------------------------------------//
  function isKeyEmpty(array, key) {
    for (let obj of array) {
      if (obj.hasOwnProperty(key)) {
        // Check if the key exists and if its value is considered empty
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
          return true; // Key is considered empty
        }
      }
    }
    return false; // Key is not empty in any object
  }
  //---------------------------------------------  Function to check empty field ends ---------------------------------------------------//

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = (event) => {
    event.preventDefault();
    if (id) {
      const url = `${BASE_URL}modulePost/${id}/`;
      let isEmpty = isKeyEmpty(moduleData, "module"); // Checking if any field is empty

      if (!isEmpty) {
        Request.post(url, moduleData)
          .then(() => {
            handleFormStep(3);
          })
          .catch((err) => {
            alert("Error fetching clients", err);
            console.log("Error fetching clients", err);
          })
          .finally(() => {});
      } else {
        alert("All modules are required.");
      }
    }
  };
  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//

  //------------------------------------------------ useEffect to fetch Module Data in Case of Edit Starts -----------------------------------------//
  useEffect(() => {
    if (id) {
      setLoader(true);
      Request.get(`${BASE_URL}/moduleGet?id=${id}`)
        .then((res) => {
          if (res.data.length == 0) {
            setModuleData([
              {
                id: 1,
                module: "",
                course: id,
              },
            ]);
          } else {
            setModuleData(res.data);
          }
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [isEdit]);
  //------------------------------------------------ useEffect to fetch Module Data in Case of Edit Ends -----------------------------------------//

  //---------------------------------------------  Function to change dynamic fields values starts ---------------------------------------------------//
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setModuleData((prevObjects) => {
      const updatedObjects = [...prevObjects];
      console.log(updatedObjects);
      updatedObjects[index].module = value;
      return updatedObjects;
    });
  };
  //---------------------------------------------  Function to change dynamic fields values ends ---------------------------------------------------//
  return (
    <div>
      <div>
        <form className={styles.primaryModuleForm}>
          {moduleData.length >= 0 &&
            moduleData?.map((field, index) => (
              <div className={styles.formFields} key={field.id}>
                <div className={styles.fieldDiv}>
                  <label>Main Module {index + 1}</label>
                  <input
                    type="text"
                    name={`MainModule${field.id}`}
                    placeHolder="Module Name"
                    value={field.module}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              </div>
            ))}

          <div className={styles.addModuleButtonDiv}>
            <button
              className={styles.addModuleButton}
              type="button"
              onClick={() => handleAddField()}
            >
              <img src={AddIcon} />
              Add Module
            </button>

            {moduleData?.length >= 2 && (
              <button
                className={styles.addModuleButton}
                type="button"
                onClick={() => handleRemoveField()}
              >
                <img src={RemoveIcon} />
                Remove Module
              </button>
            )}
          </div>

          <div className={styles.nextButtonDiv}>
            <button
              className={styles.addModuleButton}
              type="button"
              onClick={() => handleFormStep(1)}
            >
              Previous
            </button>
            <button className={styles.nextButton} onClick={handleSubmit}>
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PrimaryModule;
