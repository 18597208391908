import React, { useState } from "react";
import styles from "../../assets/styles/CourseDetail.module.css";
import ReviewPage from "./ReviewPage";
import CourseContentPage from "./CourseContentPage";

function Section3({ id }) {
  const loginCheck = localStorage.getItem("loginUser");

  const [activeTab, setActiveTab] = useState("coursecontent");
  return (
    <div className={styles.section3Maindiv}>
      <div className={styles.section3}>
        <div className={styles.headingBar}>
          <div className={styles.headingItemDiv}>
            <button
              onClick={() => {
                setActiveTab("coursecontent");
              }}
            >
              <p
                className={
                  activeTab == "coursecontent"
                    ? styles.headingText + " " + styles.activeTab
                    : styles.headingText
                }
              >
                Course Content
              </p>
            </button>
          </div>
          <div className={styles.headingItemDiv}>
            {loginCheck && (
              <button
                onClick={() => {
                  setActiveTab("review");
                }}
              >
                <p
                  className={
                    activeTab === "review"
                      ? styles.headingText + " " + styles.activeTab
                      : styles.headingText
                  }
                >
                  Reviews
                </p>
              </button>
            )}
          </div>
        </div>
      </div>
      {activeTab === "review" ? <ReviewPage id={id} /> : <CourseContentPage id={id} />}
    </div>
  );
}

export default Section3;
