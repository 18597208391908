import styles from '../../assets/styles/Course.module.css'
import clockImg from '../../assets/images/ClockImg.png' 
import rating from '../../assets/images/Rating.png' 
import rightArrowOrange from '../../assets/images/rightArrowOrange.png' 
import {  useNavigate } from "react-router-dom";
import React, { useState } from "react";
import emptyStar from "../../assets/images/emptyStar.png";
import star from "../../assets/images/star.png";

function Listview({ imageSrc, courseTitle, courseDetail, timeDetail, cardID }) {
    const navigation = useNavigate();
    const [starRating, setStarRating] = useState(Math.random() * (5 - 3) + 3);

  return (
   <>
        <div className={styles.mainDivFlex}>

            <div className={styles.subOneDiv}>
                <img src={imageSrc}  className={styles.cardImgList} />
            </div>

            <div className={styles.subTwoDiv}>
                <span className={styles.courseTitle}>
                    {courseTitle}
                </span>
                <p className={styles.courseDetail}>
                    {courseDetail}
                </p>
                <div className={styles.cardFooter}>
                    <img src={clockImg} alt="Clock Icon" className={styles.clockIcon} />
                    <p className={styles.timeDetail}>
                    {timeDetail}
                    </p>
                    <div className={styles.footerVL}></div>
                    {/* <img src={rating} alt="Feedback Icon"  /> */}

                      <div>
                          <img
                              src={starRating > 0 ? star : emptyStar}
                              alt="Rating Icon"
                              className={styles.ratingIconAll}
                          />
                          <img
                              src={starRating > 1 ? star : emptyStar}
                              alt="Rating Icon"
                              className={styles.ratingIconAll}
                          />
                          <img
                              src={starRating > 2 ? star : emptyStar}
                              alt="Rating Icon"
                              className={styles.ratingIconAll}
                          />
                          <img
                              src={starRating > 3 ? star : emptyStar}
                              alt="Rating Icon"
                              className={styles.ratingIconAll}
                          />
                          <img
                              src={starRating > 4 ? star : emptyStar}
                              alt="Rating Icon"
                              className={styles.ratingIconAll}
                          />
                      </div>
                  </div>  
                <div className={styles.lerarnMoreFlex}>
                    <p className={styles.learnMoreText} onClick={() => {
                          // navigation("/coursedetail");
                          navigation(`/coursedetail/${cardID}`, { state: { data: cardID, rating: starRating } });

              }}>
                        Learn More
                    </p>
                    <img src={rightArrowOrange} alt="Feedback Icon" className={styles.rightArrowSet} />
                </div>
            </div>

        </div>
        <div className={styles.HLine}> </div>
   </>
  )
}

export default Listview