import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/CourseDetail.module.css";
import CourseContentCard from "./CourseContentCard";
import { useLocation } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import useLoader from "../../hooks/useLoader";
import { useParams } from "react-router-dom";

function CourseContentPage() {
  const params = useParams();

  const location = useLocation();
  const id = location?.state?.data || params?.id;
  const [data, setData] = useState();
  const { isLoading, setLoader, Loader } = useLoader();

  useEffect(() => {
    setLoader(true);
    Request.get(`${BASE_URL}modSubModGet?id=${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        alert("Error fetching category", err);
        console.log("Error fetching category", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  return (
    <div className={styles.courseContentDiv}>
      {isLoading ? (
        <Loader type="component" />
      ) : (
        data?.map((val, i) => {
          return <CourseContentCard key={String(i)} data={val} />;
        })
      )}
    </div>
  );
}

export default CourseContentPage;
