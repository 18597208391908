import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/Admin/timeline.module.css";

const CustomTimeline = ({ handleFormStep, currentStep, isEdit }) => {
  const [activeStep, setActiveStep] = useState(1); // State to manage active step

  // Function to handle click on a step
  const handleStepClick = (step) => {
    setActiveStep(step);
    handleFormStep(step);
  };

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);
  return (
    <div className={styles.timeline}>
      <div className={styles["steps-container"]}>
        <div className={styles.line}>
          <span
            className={`${styles.coloredLine} ${
              activeStep == 1
                ? styles.empty
                : activeStep == 2
                ? styles.oneFourth
                : activeStep == 3
                ? styles.half
                : styles.full
            }`}
          ></span>
        </div>
        <div
          style={{ cursor: isEdit ? "pointer" : "no-drop" }}
          className={`${styles.step} ${styles.firststep} ${
            activeStep > 1 ? styles.active : ""
          } ${activeStep == 1 ? styles.current : ""}`}
          onClick={() => (isEdit ? handleStepClick(1) : "")}
        >
          <div className={activeStep == 1 ? styles.currentCircle : ""}></div>
        </div>
        <div
          style={{ cursor: isEdit ? "pointer" : "no-drop" }}
          className={`${styles.step} ${styles.secondstep} ${
            activeStep > 2 ? styles.active : ""
          } ${activeStep == 2 ? styles.current : ""}`}
          onClick={() => (isEdit ? handleStepClick(2) : "")}
        >
          {" "}
          <div className={activeStep == 2 ? styles.currentCircle : ""}></div>
        </div>
        <div
          style={{ cursor: isEdit ? "pointer" : "no-drop" }}
          className={`${styles.step} ${styles.thirdstep} ${
            activeStep > 3 ? styles.active : ""
          } ${activeStep == 3 ? styles.current : ""}`}
          onClick={() => (isEdit ? handleStepClick(3) : "")}
        >
          {" "}
          <div className={activeStep == 3 ? styles.currentCircle : ""}></div>
        </div>
        <div
          style={{ cursor: isEdit ? "pointer" : "no-drop" }}
          className={`${styles.step} ${styles.fourthstep} ${
            activeStep > 4 ? styles.active : ""
          } ${activeStep == 4 ? styles.current : ""}`}
          onClick={() => (isEdit ? handleStepClick(4) : "")}
        >
          {" "}
          <div className={activeStep == 4 ? styles.currentCircle : ""}></div>
        </div>
      </div>
    </div>
  );
};

export default CustomTimeline;
