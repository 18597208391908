import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/styles/Review.module.css";
import ReviewCard from "./ReviewCard";
import emptyStar from "../../assets/images/emptyStar.png";
import star from "../../assets/images/star.png";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";

function ReviewPage({ id }) {
  const navigation = useNavigate();
  const title = useRef();
  const name = useRef();
  const summary = useRef();
  const [starRating, setStarRating] = useState(0);
  const loginCheck = localStorage.getItem("loginUser");
  const loginCheckEmail = localStorage.getItem("email");
  const loginCheckId = localStorage.getItem("id");
  const [data, setData] = useState();
  const [reloadReview, seReloadReview] = useState(false);
  const {
    isLoading: isReviewSubmitLoading,
    setLoader: setReviewSubmitLoader,
    Loader: ReviewSubmitLoader,
  } = useLoader();
  const {
    isLoading: isReviewLoading,
    setLoader: setReviewLoader,
    Loader: ReviewLoader,
  } = useLoader();

  async function createReview() {
    setReviewSubmitLoader(true);
    Request.post(`${BASE_URL}review`, {
      rating: document.getElementById("ratingID").innerText,
      title: title.current.value,
      signupname: name.current.value,
      course: id,
      summary: summary.current.value,
    })
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Data Submitted Successfully.");
          seReloadReview(!reloadReview);
          // navigation("/courses");
        } else {
          toast.error("Error in data sending.");
        }
      })
      .catch((err) => {
        alert("Error course details user: ", err);
        alert(err.response.data);
      })
      .finally(() => {
        setReviewSubmitLoader(false);
      });
  }
  useEffect(() => {
    setReviewLoader(true);
    Request.get(`${BASE_URL}reviewGet?id=${id}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        alert("Error fetching category", err);
        console.log("Error fetching category", err);
      })
      .finally(() => {
        setReviewLoader(false);
      });
  }, [reloadReview]);
  return (
    <div className={styles.section}>
      <div className={styles.reviewDiv}>
        <p className={styles.titleText}> {data?.length} Reviews</p>
        {isReviewLoading ? (
          <ReviewLoader type="component" />
        ) : (
          data?.map((val, i) => {
            return (
              <>
                <ReviewCard
                  key={String(i)}
                  Rname={val?.signupname}
                  Rdate={val?.created_at}
                  Rtitle={val?.title}
                  Rsummary={val?.summary}
                  stars={val?.rating}
                />
              </>
            );
          })
        )}

        <div className={styles.hLine}></div>
      </div>
      <div className={styles.reviewFormDiv}>
        <p className={styles.titleText}> Write a Review</p>
        {isReviewSubmitLoading ? (
          <ReviewSubmitLoader type="component" />
        ) : (
          <>
            <div className={styles.ratingDiv}>
              <div>
                <img
                  onClick={() => {
                    setStarRating((i) => (i == 1 ? 0 : 1));
                  }}
                  src={starRating > 0 ? star : emptyStar}
                  alt="Rating Icon"
                  className={styles.ratingIconAll}
                />
                <img
                  onClick={() => {
                    setStarRating((i) => (i == 2 ? 1 : 2));
                  }}
                  src={starRating > 1 ? star : emptyStar}
                  alt="Rating Icon"
                  className={styles.ratingIconAll}
                />
                <img
                  onClick={() => {
                    setStarRating((i) => (i == 3 ? 2 : 3));
                  }}
                  src={starRating > 2 ? star : emptyStar}
                  alt="Rating Icon"
                  className={styles.ratingIconAll}
                />
                <img
                  onClick={() => {
                    setStarRating((i) => (i == 4 ? 3 : 4));
                  }}
                  src={starRating > 3 ? star : emptyStar}
                  alt="Rating Icon"
                  className={styles.ratingIconAll}
                />
                <img
                  onClick={() => {
                    setStarRating((i) => (i == 5 ? 4 : 5));
                  }}
                  src={starRating > 4 ? star : emptyStar}
                  alt="Rating Icon"
                  className={styles.ratingIconAll}
                />
              </div>
              <p className={styles.titleText} id="ratingID">
                {starRating}
              </p>
            </div>
            <div className={styles.inputData}>
              <label for="" className={styles.formLabel}>
                Review Title
              </label>
              <input
                type="text"
                className={styles.forminput}
                ref={title}
                placeholder="Review Title"
                required
              />
            </div>
            <div className={styles.inputData}>
              <label for="" className={styles.formLabel}>
                Reviewer Name
              </label>
              <input
                type="text"
                className={styles.forminput}
                value={loginCheck}
                readOnly
                placeholder="Name"
                required
              />
            </div>
            <div className={styles.inputData}>
              <label for="" className={styles.formLabel}>
                Reviewer Email
              </label>
              <input
                type="text"
                className={styles.forminput}
                value={loginCheckEmail}
                readOnly
                placeholder="Email"
                required
              />
              <input
                type="text"
                className={styles.forminput}
                value={loginCheckId}
                hidden
                ref={name}
                placeholder="Email"
                required
              />
            </div>
            <div className={styles.inputData}>
              <label for="" className={styles.formLabel}>
                Review Summary
              </label>
              <textarea
                className={styles.formtextarea}
                ref={summary}
                placeholder="Summary"
                required
              />
            </div>
            <div className={styles.btnDiv}>
              {loginCheck ? (
                <>
                  <button className={styles.submitBtn} onClick={() => createReview()}>
                    <p className={styles.submitBtnText}>Submit Review</p>
                  </button>
                </>
              ) : (
                <>
                  <button className={styles.submitDisableBtn} disabled>
                    <p className={styles.submitDisableBtnText}>Submit Review</p>
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ReviewPage;
