import React from "react";
import styles from "../../assets/styles/Admin/AdminLayout.module.css";

const AdminChild = ({ children }) => {
  return (
    <div className={styles.adminChild}>

      {children}
    </div>
  );
};

export default AdminChild;
