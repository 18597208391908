//--------------------------------------------------------------- All Imports Starts -------------------------------------------------------------------//
import Request from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddIcon from "../../../assets/images/addBlueIcon.svg";
import RemoveIcon from "../../../assets/images/removeBlueIcon.svg";
import styles from "../../../assets/styles/Admin/Courses.module.css";
import useLoader from "../../../hooks/useLoader";
import { BASE_URL } from "../../baseURL";
//--------------------------------------------------------------- All Imports Ends -------------------------------------------------------------------//

const SecondaryModule = ({ handleFormStep, isEdit }) => {
  //---------------------------------------------  Initializing states, variables and hooks starts ---------------------------------------------------//
  let courseId = useSelector((state) => state.CoursesReducers.addedCourseId);
  const params = useParams();
  const id = params?.id || courseId;
  const { isLoading, setLoader, Loader } = useLoader();
  const [moduleData, setModuleData] = useState();
  const [subModuleData, setSubModuleData] = useState([]);
  let parentToCheck;
  let count = 1;

  //---------------------------------------------  Initializing states, variables and hooks ends ---------------------------------------------------//

  //---------------------------------------------  Function to remove dynamic field starts ---------------------------------------------------//
  const handleRemoveField = (idToRemove) => {
    let newData = [...subModuleData];
    let lastIndex = -1;
    for (let i = newData.length - 1; i >= 0; i--) {
      if (newData[i].module === idToRemove) {
        lastIndex = i;
        break;
      }
    }
    // If found, remove the item at lastIndex
    if (lastIndex !== -1) {
      newData.splice(lastIndex, 1);
    }
    // Update state to trigger re-render
    setSubModuleData(newData);
  };
  //---------------------------------------------  Function to remove dynamic field ends ---------------------------------------------------//

  //---------------------------------------------  Function to check empty field starts ---------------------------------------------------//
  function isKeyEmpty(array, key) {
    for (let obj of array) {
      if (obj.hasOwnProperty(key)) {
        // Check if the key exists and if its value is considered empty
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
          return true; // Key is considered empty
        }
      }
    }
    return false; // Key is not empty in any object
  }
  //---------------------------------------------  Function to check empty field ends ---------------------------------------------------//

  //---------------------------------------------  Function to calculate index of dynamic input starts ---------------------------------------------------//
  function subModulelength(parentId, idToFind) {
    if (parentToCheck == parentId) {
      count++;
    } else {
      parentToCheck = parentId;
      count = 1;
    }
    return count;
  }
  //---------------------------------------------  Function to calculate index of dynamic input starts ---------------------------------------------------//

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = (event) => {
    event.preventDefault();
    if (id) {
      const url = `${BASE_URL}submodulePost/${id}/`;
      let isEmpty = isKeyEmpty(moduleData, "module"); // Checking if any field is empty

      if (!isEmpty) {
        Request.post(url, subModuleData)
          .then(() => {
            handleFormStep(4);
          })
          .catch((err) => {
            alert("Error fetching clients", err);
            console.log("Error fetching clients", err);
          })
          .finally(() => {});
      } else {
        alert("All secondary modules are required.");
      }
    }
  };
  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//

  //------------------------------------------------ useEffect to fetch Module Data in Case of Edit Starts -----------------------------------------//
  useEffect(() => {
    if (id) {
      setLoader(true);
      Request.get(`${BASE_URL}/moduleGet?id=${id}`)
        .then((res) => {
          setModuleData(res.data);
          const newFields = [];
          res.data.map((field) => {
            newFields.push({
              id: subModuleData.length + 1,
              submodule: "",
              module: field.id,
            });
          });
          // setSubModuleData(newFields);
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {});
    }
  }, [isEdit]);
  //------------------------------------------------ useEffect to fetch Module Data in Case of Edit Ends -----------------------------------------//

  //------------------------------------------------ useEffect to fetch Sub Module Data in Case of Edit Starts -----------------------------------------//
  useEffect(() => {
    if (id) {
      Request.get(`${BASE_URL}/submoduleGet?id=${id}`)
        .then((res) => {
          // if (res.data.length == 0) {
          //   setSubModuleData({
          //     id: 1,
          //     submodule: "",
          //     module: id,
          //   });
          // } else {
          setSubModuleData(res.data);
          // }
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [isEdit]);
  //------------------------------------------------ useEffect to fetch Sub Module Data in Case of Edit Ends -----------------------------------------//

  //---------------------------------------------  Function to add dynamic field starts ---------------------------------------------------//
  const addModules = (id) => {
    const newFields = [
      ...subModuleData,
      { id: subModuleData.length + 1, submodule: "", module: id },
    ];
    setSubModuleData(newFields);
  };
  //---------------------------------------------  Function to add dynamic field ends ---------------------------------------------------//

  const handleInputChange = (e, modid, subid) => {
    const { value } = e.target;

    function updateObjectById(array, modid, id, update) {
      return array.map((obj) => {
        if (obj.id === id && obj.module == modid) {
          return { ...obj, ...update };
        } else {
          return obj;
        }
      });
    }

    let updatedArray = updateObjectById(subModuleData, modid, subid, {
      submodule: value,
    });
    setSubModuleData(updatedArray);
  };

  //---------------------------------------------  Function to change dynamic fields values starts ---------------------------------------------------//
  const countEntriesForModule = (data, moduleNumber) => {
    let count = 0;
    data.length > 0 &&
      data?.forEach((entry) => {
        if (entry.module === moduleNumber) {
          count++;
        }
      });
    return count;
  };
  //---------------------------------------------  Function to change dynamic fields values ends ---------------------------------------------------//

  return (
    <div>
      {isLoading ? (
        <Loader type={"component"} />
      ) : (
        <div>
          <form className={styles.primaryModuleForm}>
            {moduleData?.map((data, moduleIndex) => (
              <div key={data.id} className={styles.secondaryFields}>
                <p className={styles.title}>{data?.module}</p>
                {subModuleData.length > 0 &&
                  subModuleData?.map(
                    (field, subModuleIndex) =>
                      field?.module == data.id && (
                        <div className={styles.formFields} key={field.id}>
                          <div className={styles.fieldDiv}>
                            <label>
                              Secondary Module{" "}
                              {subModulelength(
                                data.id,
                                field.id
                              )}
                            </label>
                            <input
                              type="text"
                              placeHolder="Module Name"
                              value={field?.submodule}
                              onChange={(e) =>
                                handleInputChange(e, data.id, field.id)
                              }
                            />
                          </div>
                        </div>
                      )
                  )}

                <div className={styles.addModuleButtonDiv}>
                  <button
                    className={styles.addModuleButton}
                    type="button"
                    onClick={() => addModules(data.id)}
                  >
                    <img src={AddIcon} />
                    Add Module
                  </button>

                  {countEntriesForModule(subModuleData, data.id) >= 2 && (
                    <button
                      className={styles.addModuleButton}
                      type="button"
                      onClick={() => handleRemoveField(data.id)}
                    >
                      <img src={RemoveIcon} />
                      Remove Module
                    </button>
                  )}
                </div>
              </div>
            ))}

            <div className={styles.nextButtonDiv}>
              <button
                className={styles.addModuleButton}
                type="button"
                onClick={() => handleFormStep(2)}
              >
                Previous
              </button>
              <button
                className={styles.nextButton}
                type="submit"
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default SecondaryModule;
