import React from "react";
import styles from "../../assets/styles/HomePage.module.css";
import instructorImg from "../../assets/images/HomePageInstructorImg.png";

function Section3({ setShowModal }) {
  return (
    <div className={styles.section3}>
      <div className={styles.mainsection}>
        <div className={styles.section3TextDiv}>
          <div className={styles.combinedTitle}>
            <p className={styles.section3TitleText}>
              If you are a certified teacher then
            </p>
            <p className={styles.section3YellowText}>Become An Instructor</p>
          </div>

          <p className={styles.section3SubText}>
            Unlock the opportunity to inspire and educate by joining our team of
            instructors. If you’re a certified teacher, elevate your impact and
            share your knowledge with learners.
          </p>
          <button
            className={styles.registerBtn}
            id="instructorBtn"
            onClick={() => {
              setShowModal("Register");
            }}
          >
            <div>
              <p className={styles.registerBtnText}>Become an Instructor</p>
            </div>
          </button>
        </div>
        <div className={styles.section3ImgDiv}>
          <img
            src={instructorImg}
            alt="Instructor Image"
            className={styles.instructorImg}
          />
        </div>
      </div>
    </div>
  );
}

export default Section3;
