import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/HomePage.module.css";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import useLoader from "../../hooks/useLoader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
let media = "media";

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 2.3,
  swipeToSlide: true,
  autoplay: false,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  speed: 10,
  cssEase: "ease-in",
  responsive: [
    {
      breakpoint: 2400,
      settings: "unslick",
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2.3,
      },
    },
    {
      breakpoint: 768,
      settings: "unslick",
    },
  ],
};
const settings1 = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 6,
  swipeToSlide: true,
  autoplay: false,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  speed: 10,
  cssEase: "ease-in",
  variableWidth: true,
};
function Section2() {
  const [filter, setFilter] = useState("All Categories");
  const navigation = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState("All Categories");
  const [coursesHome, setCoursesHome] = useState();
  const {
    isLoading: isCourseLoading,
    setLoader: setCourseLoader,
    Loader: CourseLoader,
  } = useLoader();
  const {
    isLoading: isCategoryLoading,
    setLoader: setCategoryLoader,
    Loader: CategoryLoader,
  } = useLoader();

  useEffect(() => {
    setCourseLoader(true);
    Request.get(`${BASE_URL}courseGet`)
      .then((res) => {
        setCoursesHome(res?.data);
      })
      .catch((err) => {
        alert("Error fetching home courses", err);
        console.log("Error fetching home courses", err);
      })
      .finally(() => {
        setCourseLoader(false);
      });
  }, []);
  const [categoryHome, setCategoryHome] = useState();

  useEffect(() => {
    setCategoryLoader(true);
    Request.get(`${BASE_URL}categoryGet`)
      .then((res) => {
        setCategoryHome(res?.data);
      })
      .catch((err) => {
        alert("Error fetching category", err);
        console.log("Error fetching category", err);
      })
      .finally(() => {
        setCategoryLoader(false);
      });
  }, []);
  // const categoryHome = [
  //     { course: "All Categories" },
  // ]
  {
    categoryHome?.map((val, i) => {
      // console.log(val)
      return { course: val.name };
    });
  }

  {
    coursesHome?.map((val, i) => {
      return {
        imageSrc: val.image,
        courseTitle: val.category,
        courseDetail: val.title,
        timeDetail: val.duration,
      };
    });
  }

  return (
    <div className={styles.section2}>
      <div className={styles.setMarginSection2}>
        <div className={styles.section2TitleDiv}>
          <p className={styles.section2TitleText}>Featured Courses</p>
          <p className={styles.section2SubText}>Pick a Course to Get Started</p>
        </div>

        <div className={styles.courseMapDiv}>
          <Slider {...settings1}>
            <div className={styles.courseDiv}>
              <button
                className={styles.courseBtn}
                onClick={() => {
                  setSelectedIndex("All Categories");
                  setFilter(() => "All Categories");
                }}
              >
                <p
                  className={
                    selectedIndex === "All Categories"
                      ? styles.courseBtnText
                      : styles.courseBtnText2
                  }
                >
                  {!isCategoryLoading && "All Categories"}
                </p>
              </button>
            </div>
            {isCategoryLoading ? (
              <CategoryLoader type="component" />
            ) : (
              categoryHome?.map((val, i) => {
                return (
                  <div className={styles.courseDiv}>
                    <button
                      className={styles.courseBtn}
                      onClick={() => {
                        setSelectedIndex(() => i);
                        setFilter(() => val.name);
                      }}
                    >
                      <p
                        className={
                          selectedIndex === i
                            ? styles.courseBtnText
                            : styles.courseBtnText2
                        }
                      >
                        {val.name}
                      </p>
                    </button>
                  </div>
                );
              })
            )}
          </Slider>
        </div>

        <div className={styles.cardHolder}>
          {isCourseLoading ? (
            <CourseLoader type="component" />
          ) : (
            <Slider {...settings}>
              {coursesHome?.map((val, i) => {
                if (filter == "All Categories" && i <= 3) {
                  return (
                    <Card
                      imageSrc={BASE_URL +  val.image}
                      courseTitle={val.category}
                      courseDetail={val.title}
                      timeDetail={val.duration}
                      cardID={val.id}
                    />
                  );
                }

                if (filter == val.category) {
                  return (
                    <Card
                      imageSrc={BASE_URL + val.image}
                      courseTitle={val.category}
                      courseDetail={val.title}
                      timeDetail={val.duration}
                      cardID={val.id}
                    />
                  );
                }
              })}
            </Slider>
          )}

          {/* <Card imageSrc={FrontEndImg}
                        courseTitle={"Front-End"}
                        courseDetail={"React.js - Building Interactive User Interfaces"}
                        timeDetail={"10 Weeks"} />
                    <Card imageSrc={FrontEndImg}
                        courseTitle={"Front-End"}
                        courseDetail={"React Native Building Cross-Platform Mobile Apps"}
                        timeDetail={"10 Weeks"} />
                    <Card imageSrc={BackEndImg}
                        courseTitle={"Back -End"}
                        courseDetail={"The Complete DBMS Bootcamp"}
                        timeDetail={"10 Weeks"} /> */}
        </div>
      </div>

      <button
        className={styles.browseBtn}
        id="browseBtn"
        onClick={() => navigation("/courses")}
      >
        <div>
          <p className={styles.registerBtnText}> Browse More Courses</p>
        </div>
      </button>
    </div>
  );
}

export default Section2;
