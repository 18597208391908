import { CURRENT_ROUTE,USER_ROLE } from "../actions/ActionCreators";

const initialState = {
  current_route: "/",
  user_role:"User",
};

export function allData(state = initialState, action) {
  switch (action.type) {
    case CURRENT_ROUTE:
      return { ...state, current_route: action.payload };
    case USER_ROLE:
      return { ...state, user_role: action.payload };
    default:
      return state;
    // break;
  }
}
