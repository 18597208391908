//--------------------------------------------------------------- All Imports Starts -------------------------------------------------------------------//
import Request from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "../../../assets/images/addBlueIcon.svg";
import RemoveIcon from "../../../assets/images/removeBlueIcon.svg";
import styles from "../../../assets/styles/Admin/Courses.module.css";
import useLoader from "../../../hooks/useLoader";
import { BASE_URL } from "../../baseURL";
//--------------------------------------------------------------- All Imports Ends -------------------------------------------------------------------//

const TertiaryModule = ({ handleFormStep, isEdit }) => {
  //---------------------------------------------  Initializing states, variables and hooks starts ---------------------------------------------------//
  const params = useParams();
  let courseId = useSelector((state) => state.CoursesReducers.addedCourseId);
  const id = params?.id || courseId;
  const { setLoader } = useLoader();
  const [upperModules, setUpperModules] = useState();
  const [tertiaryModule, setTertiaryModule] = useState([]);
  const navigate = useNavigate();
  let parentToCheck;
  let count = 1;
  //---------------------------------------------  Initializing states, variables and hooks ends ---------------------------------------------------//

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = (event) => {
    event.preventDefault();
    let isEmpty = isKeyEmpty(tertiaryModule, "subsubmodule"); // Checking if any field is empty

    if (!isEmpty) {
      const url = `${BASE_URL}subsubmodulePost/${id}/`;
      Request.post(url, tertiaryModule)
        .then(() => {
          navigate("../");
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {});
    } else {
      alert("All tertiary fields are required.");
    }
  };
  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//

  //---------------------------------------------  Function to calculate index of dynamic input starts ---------------------------------------------------//
  function subModulelength(parentId) {
    if (parentToCheck == parentId) {
      count++;
    } else {
      parentToCheck = parentId;
      count = 1;
    }
    return count;
  }
  //---------------------------------------------  Function to calculate index of dynamic input starts ---------------------------------------------------//

  //---------------------------------------------  Function to check empty field starts ---------------------------------------------------//
  function isKeyEmpty(array, key) {
    for (let obj of array) {
      if (obj.hasOwnProperty(key)) {
        // Check if the key exists and if its value is considered empty
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
          return true; // Key is considered empty
        }
      }
    }
    return false; // Key is not empty in any object
  }
  //---------------------------------------------  Function to check empty field ends ---------------------------------------------------//

  //---------------------------------------------  Function to add dynamic field starts ---------------------------------------------------//
  const addModules = (id) => {
    const newFields = [
      ...tertiaryModule,
      { id: tertiaryModule.length + 1, subsubmodule: "", submodule: id },
    ];
    setTertiaryModule(newFields);
  };
  //---------------------------------------------  Function to add dynamic field ends ---------------------------------------------------//

  //---------------------------------------------  Function to remove dynamic field starts ---------------------------------------------------//
  const handleRemoveField = (idToRemove) => {
    let newData = [...tertiaryModule];
    let lastIndex = -1;
    for (let i = newData.length - 1; i >= 0; i--) {
      if (newData[i].submodule === idToRemove) {
        lastIndex = i;
        break;
      }
    }
    // If found, remove the item at lastIndex
    if (lastIndex !== -1) {
      newData.splice(lastIndex, 1);
    }
    // Update state to trigger re-render
    setTertiaryModule(newData);
  };
  //---------------------------------------------  Function to remove dynamic field ends ---------------------------------------------------//

  //------------------------------------------------ useEffect to fetch Module & Sub Module Data in Case of Edit Starts -----------------------------------------//
  useEffect(() => {
    if (id) {
      setLoader(true);
      Request.get(`${BASE_URL}/modSubModGet?id=${id}`)
        .then((res) => {
          setUpperModules(res.data);
          const newFields = [];
          res.data.map((secondary) =>
            secondary.subMod.map((tertiary) =>
              tertiary.subsubmod.map((data) => newFields.push(data))
            )
          );
          setTertiaryModule(newFields);
        })
        .catch((err) => {
          alert("Error fetching clients", err);
          console.log("Error fetching clients", err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [isEdit]);
  //------------------------------------------------ useEffect to fetch  Module & Sub Module Data in Case of Edit Ends -----------------------------------------//

  //---------------------------------------------  Function to change dynamic fields values starts ---------------------------------------------------//
  const handleInputChange = (e, submodid, subsubmodid) => {
    const { value } = e.target;

    function updateObjectById(array, submodid, id, update) {
      return array.map((obj) => {
        if (obj.id === id && obj.submodule == submodid) {
          return { ...obj, ...update };
        } else {
          return obj;
        }
      });
    }

    let updatedArray = updateObjectById(tertiaryModule, submodid, subsubmodid, {
      subsubmodule: value,
    });
    setTertiaryModule(updatedArray);
  };
  //---------------------------------------------  Function to change dynamic fields values ends ---------------------------------------------------//

  //------------------------------------  Function to counts total dynamic fields for sub module starts -----------------------------------------------//
  const countEntriesForModule = (data, moduleNumber) => {
    let count = 0;
    data.forEach((entry) => {
      if (entry.submodule === moduleNumber) {
        count++;
      }
    });
    return count;
  };
  //------------------------------------  Function to counts total dynamic fields for sub module ends -----------------------------------------------//
  return (
    <div>
      <div>
        <form className={styles.tertiaryModuleForm}>
          {upperModules?.map((module) =>
            module?.subMod?.map((submodule) => (
              <div key={submodule.id} className={styles.secondaryFields}>
                <p className={styles.title}>
                  {module.module}
                  <div className={styles.titleDivider}></div>
                  {submodule.name}
                </p>

                {tertiaryModule?.map(
                  (field) =>
                    field.submodule == submodule.id && (
                      <div className={styles.secondaryFields}>
                        <div className={styles.fieldDiv}>
                          <label>
                            Tertiary Module {subModulelength(submodule.id, field.id)}
                          </label>
                          <input
                            type="text"
                            placeHolder="Module Name"
                            value={field.subsubmodule}
                            onChange={(e) =>
                              handleInputChange(e, submodule.id, field.id)
                            }
                          />
                        </div>
                      </div>
                    )
                )}

                <div className={styles.addModuleButtonDiv}>
                  <button
                    className={styles.addModuleButton}
                    type="button"
                    onClick={() => addModules(submodule.id)}
                  >
                    <img src={AddIcon} />
                    Add Module
                  </button>

                  {countEntriesForModule(tertiaryModule, submodule.id) >= 2 && (
                    <button
                      className={styles.addModuleButton}
                      type="button"
                      onClick={() => handleRemoveField(submodule.id)}
                    >
                      <img src={RemoveIcon} />
                      Remove Module
                    </button>
                  )}
                </div>
              </div>
            ))
          )}

          <div className={styles.nextButtonDiv}>
            <button
              className={styles.addModuleButton}
              type="button"
              onClick={() => handleFormStep(3)}
            >
              Previous
            </button>
            <button
              className={styles.nextButton}
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TertiaryModule;
