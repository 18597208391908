import React from "react";
import styles from "../../assets/styles/Admin/Courses.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../baseURL";

const CoursesCard = ({ courseData, setSelectedCourse, setDeleteModalOpen }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.courseCard}>
      <div className={styles.courseCardDetails}>
        <div className={styles.imgContainer}>
          <div className={styles.imgDiv}>
            <img src={`${BASE_URL}${courseData.image}`} />
          </div>
        </div>
        <div className={styles.courseTitle}>{courseData.title}</div>
        <div className={styles.instructorDetails}>
          <span>Instructor</span>
          <p className={styles.instructorName}>{courseData?.instructor}</p>
        </div>
      </div>
      <div className={styles.courseCardAction}>
        <span
          className={styles.courseEdit}
          onClick={(e) => navigate(`../admin/editCourse/${courseData.id}/`)}
        >
          Edit
        </span>
        <div className={styles.courseActionSeperator}></div>
        <span
          className={styles.courseDelete}
          onClick={() => {
            setSelectedCourse(courseData.id);
            setDeleteModalOpen(true);
          }}
        >
          Delete
        </span>
      </div>
    </div>
  );
};

export default CoursesCard;
