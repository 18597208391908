import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/Review.module.css";
import ReviewProfileImg from "../../assets/images/ReviewProfileImg.png";
import rating from "../../assets/images/Rating.png";
import emptyStar from "../../assets/images/emptyStar.png";
import star from "../../assets/images/star.png";
function ReviewCard({ Rtitle, Rname, Rsummary, Rdate, stars }) {
  const [starArray, setStarArray] = useState([]);
  useEffect(() => {
    let array = [];
    for (let i = 1; i <= 5; i++) {
      if (i > stars) {
        array.push(<img key={i} src={emptyStar} />);
      } else {
        array.push(<img key={i} src={star} />);
      }
    }
    setStarArray(array);
  });
  return (
    <div className={styles.reviewCard}>
      <div className={styles.imgDiv}>
        <img src={ReviewProfileImg} />
      </div>
      <div className={styles.infoDiv}>
        <p className={styles.name}> {Rname}</p>
        <div className={styles.ratingDiv}>
          {/* <img src={rating} alt="Rating Icon" className={styles.ratingIcon} /> */}

          {starArray?.map((val, i) => {
            return val;
          })}
          <p className={styles.reviewDate}>{Rdate} </p>
        </div>
        <p className={styles.reviewTitle}> {Rtitle}</p>
        <p className={styles.reviewSummary}>{Rsummary}</p>
      </div>
    </div>
  );
}

export default ReviewCard;
