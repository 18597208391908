// import React, { useEffect, useRef, useState } from "react";
// import styles from "../../assets/styles/Inquiry.module.css";
// import InquiryImg from "../../assets/images/InquiryImg.png";
// import { BASE_URL } from "../../components/baseURL";
// import { useNavigate } from "react-router-dom";
// import Request from "axios";
// import useLoader from "../../hooks/useLoader";

// function Section2() {
//   const navigation = useNavigate();
//   const fullname = useRef();
//   const email = useRef();
//   const phone = useRef();
//   const [status, setStatus] = useState("selectCountry");
//   const [coursesInquiry, setCoursesInquiry] = useState();
//   const [changedCourse, setChangedCourse] = useState();
//   const { isLoading: isInquiryLoading, setLoader: setInquiryLoader, Loader: InquiryLoader } = useLoader();
//   const { setLoader: setCourseLoader, Loader: CourseLoader } = useLoader();

//   useEffect(() => {
//     setCourseLoader(true);
//     Request.get(`${BASE_URL}courseGet`)
//       .then((res) => {
//         setCoursesInquiry(res?.data);
//       })
//       .catch((err) => {
//         alert("Error fetching inquiry courses", err);
//         console.log("Error fetching inquiry courses", err);
//       })
//       .finally(() => {
//         setCourseLoader(false);
//       });
//   }, []);

//   async function sendInquiry() {
//     setInquiryLoader(true);
//     const statusVal = document.getElementById("status")?.value;
//     Request.post(`${BASE_URL}inquiry`, {
//       fullname: fullname.current.value,
//       email: email.current.value,
//       phone: phone.current.value,
//       interestedcourse: changedCourse ? changedCourse : "",
//       education: document.getElementById("education").value,
//       status: statusVal ? statusVal : null,
//       country: document.getElementById("country").value,
//       emailsubscribe: document.getElementById("subscribe").checked,
//     })
//       .then((res) => {
//         if (res?.status === 200) {
//           alert("Data Submitted Successfully.");
//           // navigation("/");
//         } else {
//           alert("Error in data sending.");
//         }
//       })
//       .catch((err) => {
//         console.log("Error Inquiry: ", err);
//       })
//       .finally(() => {
//         setInquiryLoader(false);
//       });
//   }

//   return (
//     <div className={styles.section2}>
//       <CourseLoader />

//       {isInquiryLoading ? (
//         <InquiryLoader type="component" />
//       ) : (
//         <>
//           <div className={styles.imgDiv}>
//             <img src={InquiryImg} alt="Inquiry Image" className={styles.contentLogo} />
//           </div>
//           <div className={styles.formDiv}>
//             <p className={styles.titleText}>Inquiry Form</p>
//             <div className={styles.form}>
//               <div className={styles.inputData}>
//                 <label for="" className={styles.formLabel}>
//                   Full Name<p className={styles.formMandatoryLogo}>*</p>
//                 </label>
//                 <input type="text" className={styles.forminput} ref={fullname} placeholder="Full Name" />
//               </div>
//               <div className={styles.inputData}>
//                 <label for="" className={styles.formLabel}>
//                   Email<p className={styles.formMandatoryLogo}>*</p>
//                 </label>
//                 <input type="text" className={styles.forminput} ref={email} placeholder="Email" />
//               </div>
//               <div className={styles.inputData}>
//                 <label for="" className={styles.formLabel}>
//                   Phone <p className={styles.formMandatoryLogo}>*</p>
//                 </label>
//                 <input type="text" className={styles.forminput} ref={phone} placeholder="Phone No." />
//               </div>
//               <div className={styles.inputData}>
//                 <label for="" className={styles.formLabel}>
//                   Level of Education <p className={styles.formMandatoryLogo}>*</p>
//                 </label>
//                 <select className={styles.formSelectinput} id="education">
//                   <option value="please select" disabled selected hidden>
//                     Please Select
//                   </option>
//                   <option value="Less than High School Graduate">Less than High School Graduate</option>
//                   <option value="High School Graduate">High School Graduate</option>
//                   <option value="Associate Degree">Associate Degree</option>
//                   <option value="Bachelor’s Degree">Bachelor’s Degree</option>
//                   <option value="Graduate Degree or Higher">Graduate Degree or Higher</option>
//                 </select>
//               </div>
//               <div className={styles.inputData}>
//                 <label for="" className={styles.formLabel}>
//                   Course Interested <p className={styles.formMandatoryLogo}>*</p>
//                 </label>
//                 <select
//                   className={styles.formSelectinput}
//                   onChange={(s) => {
//                     setChangedCourse(s.target.value);
//                     console.log("===", s.target.value);
//                   }}
//                   id="interestedCourse">
//                   <option value="selectCourse" disabled selected hidden>
//                     Select Course
//                   </option>
//                   {coursesInquiry?.map((obj, i) => (
//                     <option value={obj.id} className={styles.element}>
//                       {obj.title}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className={styles.inputData}>
//                 <label for="" className={styles.formLabel}>
//                   Select Country <p className={styles.formMandatoryLogo}>*</p>
//                 </label>
//                 <select
//                   className={styles.formSelectinput}
//                   onChange={(e) => {
//                     setStatus(e.target.value);
//                   }}
//                   id="country">
//                   <option value="selectCountry" disabled selected hidden>
//                     Select Country
//                   </option>
//                   <option value="CANADA">CANADA</option>
//                   <option value="INDIA">INDIA</option>
//                   <option value="USA">USA</option>
//                 </select>
//               </div>

//               {status == "INDIA" && (
//                 <div className={styles.inputData}>
//                   <label for="" className={styles.formLabel}>
//                     Select Status <p className={styles.formMandatoryLogo}>*</p>
//                   </label>
//                   <select className={styles.formSelectinput} id="status">
//                     <option value="selectStatus" disabled selected hidden>
//                       Select Status
//                     </option>
//                     <option value="Full-Time Student">Full-Time Student</option>
//                     <option value="Part-Time Student">Part-Time Student</option>
//                     <option value="Full-Time Employed">Full-Time Employed</option>
//                     <option value="Part-Time Employed">Part-Time Employed</option>
//                     <option value="Unemployed">Unemployed</option>
//                   </select>
//                 </div>
//               )}

//               <div className={styles.inputData}>
//                 <div className={styles.subscribeDiv}>
//                   <input type="checkbox" id="subscribe" name="subscribe" className={styles.checkbox} /> {" "}
//                   <label for="subscribe" className={styles.text}>
//                     I subscribe to send me update and information by email.
//                   </label>
//                 </div>
//               </div>
//               <div className={styles.inputData}>
//                 <div className={styles.btnDiv}>
//                   <button className={styles.submitBtn} onClick={() => sendInquiry()}>
//                     <p className={styles.submitBtnText}>Submit</p>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default Section2;

import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/ContactUs.module.css";
import phoneIcon from "../../assets/images/ContactUsPhoneLogo.png";
import mailIcon from "../../assets/images/ContactUsEmailLogo.png";
import locationIcon from "../../assets/images/ContactUsLocationLogo.png";
import FacebookLogo from "../../assets/images/ContactUsFB.png";
import InstaLogo from "../../assets/images/ContactUsInsta.png";
import LinkedinLogo from "../../assets/images/ContactUsLinkedin.png";
import { BASE_URL } from "../../components/baseURL";
import { useNavigate } from "react-router-dom";
import Request from "axios";
import useLoader from "../../hooks/useLoader";
import styles2 from "../../assets/styles/Inquiry.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

const validationSchema = Yup.object().shape({
  fullname: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  phone: Yup.string()
    .matches(/^\+?[0-9]+$/, "Phone number can only contain digits and an optional leading +")
    .min(10, "Phone number must be at least 10 digits")
    .max(13, "Phone number must not exceed 13 digits")
    .required("Phone number is required"),
  interestedcourse: Yup.string().required("Please select a course"),
  education: Yup.string().required("Education level is required"),
  country: Yup.string().required("Country selection is required"),
  status: Yup.string().when("country", {
    is: (country) => country === "INDIA",
    then: () => Yup.string().required("Status selection is required"),
    otherwise: () => Yup.string().nullable(), // Set to null if not
  }),
});

function Section2() {
  const [coursesInquiry, setCoursesInquiry] = useState();
  const [changedCourse, setChangedCourse] = useState();
  const {
    isLoading: isInquiryLoading,
    setLoader: setInquiryLoader,
    Loader: InquiryLoader,
  } = useLoader();
  const { setLoader: setCourseLoader, Loader: CourseLoader } = useLoader();

  const initialValues = {
    fullname: "",
    email: "",
    phone: "",
    education: "",
    interestedcourse: "",
    country: "",
    status: null, // Changed here
    emailsubscribe: false,
  };

  useEffect(() => {
    setCourseLoader(true);
    Request.get(`${BASE_URL}courseGet`)
      .then((res) => {
        setCoursesInquiry(res?.data);
      })
      .catch((err) => {
        alert("Error fetching inquiry courses", err);
        console.log("Error fetching inquiry courses", err);
      })
      .finally(() => {
        setCourseLoader(false);
      });
  }, []);

  const handleSubmit = (values) => {
    setInquiryLoader(true);
    Request.post(`${BASE_URL}inquiry`, {
      ...values,
      interestedcourse: changedCourse || "",
    })
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Data Submitted Successfully.");
          // navigation("/"); // Uncomment if you want to navigate after submission
        } else {
          toast.error("Error in data sending.");
        }
      })
      .catch((err) => {
        console.log("Error Inquiry: ", err);
      })
      .finally(() => {
        setInquiryLoader(false);
      });
  };

  return (
    <div className={styles.section2}>
      <CourseLoader />
      <div className={styles.infoDiv}>
        <div className={styles.infoElement}>
          <p className={styles.titleText}>USA</p>
          <div className={styles.contentDiv}>
            <img src={phoneIcon} alt="Phone Icon" className={styles.contentLogo} />
            {/* <span className={styles.contentText}>(+1) 732 404 0466</span> */}
            <span className={styles.contentText}>732-404-0411 </span>
          </div>
          <div className={styles.contentDiv}>
            <img src={mailIcon} alt="Mail Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>info@paramacademy.com</span>
          </div>
          <div className={styles.contentDiv}>
            <img src={locationIcon} alt="Location Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>
              Middlesex Essex Turnpike #110, Iselin, NJ 08830, United States
            </span>
          </div>
        </div>
        <div className={styles.infoElement}>
          <p className={styles.titleText}>INDIA</p>
          <div className={styles.contentDiv}>
            <img src={phoneIcon} alt="Phone Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>(+91) 701 669 3717</span>
          </div>
          <div className={styles.contentDiv}>
            <img src={mailIcon} alt="Mail Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>parameduindia@gmail.com</span>
          </div>
          <div className={styles.contentDiv}>
            <img src={locationIcon} alt="Location Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>
              709 710 Everest Onyx, Opp Inox Multiplex, Race Course Rd, Vadodara, Gujarat 390007
            </span>
          </div>
        </div>
        <div className={styles.socialIconsDiv}>
          <div>
            <a href="" className={styles.linkButton} target="_blank">
              <img src={FacebookLogo} alt="" className={styles.socialIcons} />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/param-consulting-services-inc./"
              className={styles.linkButton}
              target="_blank"
            >
              <img src={LinkedinLogo} alt="" className={styles.socialIcons} />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/paramconsultingservices_?igsh=MTFqZzh5bGhxaWNpYQ=="
              className={styles.linkButton}
              target="_blank"
            >
              <img src={InstaLogo} alt="" className={styles.socialIcons} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.formDiv}>
        {isInquiryLoading ? (
          <InquiryLoader type="component" />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form className={styles2.formDiv}>
                <div className={styles2.imgDiv}></div>
                <div className={styles2.form}>
                  <div className={styles2.inputData}>
                    <label className={styles2.formLabel}>
                      Full Name<p className={styles2.formMandatoryLogo}>*</p>
                    </label>
                    <Field
                      type="text"
                      className={styles2.forminput}
                      name="fullname"
                      placeholder="Full Name"
                    />
                    <ErrorMessage name="fullname" component="div" className={styles2.error} />
                  </div>
                  <div className={styles2.inputData}>
                    <label className={styles2.formLabel}>
                      Email<p className={styles2.formMandatoryLogo}>*</p>
                    </label>
                    <Field
                      type="text"
                      className={styles2.forminput}
                      name="email"
                      placeholder="Email"
                    />
                    <ErrorMessage name="email" component="div" className={styles2.error} />
                  </div>
                  <div className={styles2.inputData}>
                    <label className={styles2.formLabel}>
                      Phone <p className={styles2.formMandatoryLogo}>*</p>
                    </label>
                    <Field
                      type="text"
                      className={styles2.forminput}
                      name="phone"
                      placeholder="Phone No."
                    />
                    <ErrorMessage name="phone" component="div" className={styles2.error} />
                  </div>
                  <div className={styles2.inputData}>
                    <label className={styles2.formLabel}>
                      Level of Education <p className={styles2.formMandatoryLogo}>*</p>
                    </label>
                    <Field as="select" className={styles2.formSelectinput} name="education">
                      <option value="" hidden label="Please Select" />
                      <option value="Less than High School Graduate">
                        Less than High School Graduate
                      </option>
                      <option value="High School Graduate">High School Graduate</option>
                      <option value="Associate Degree">Associate Degree</option>
                      <option value="Bachelor’s Degree">Bachelor’s Degree</option>
                      <option value="Graduate Degree or Higher">Graduate Degree or Higher</option>
                    </Field>
                    <ErrorMessage name="education" component="div" className={styles2.error} />
                  </div>
                  <div className={styles2.inputData}>
                    <label className={styles2.formLabel}>
                      Course Interested <p className={styles2.formMandatoryLogo}>*</p>
                    </label>
                    <Field
                      as="select"
                      className={styles2.formSelectinput}
                      name="interestedcourse"
                      onChange={(e) => {
                        setFieldValue("interestedcourse", e.target.value);
                        setChangedCourse(e.target.value);
                      }}
                    >
                      <option value="" hidden label="Select Course" />
                      {coursesInquiry?.map((obj) => (
                        <option key={obj.id} value={obj.id} className={styles2.element}>
                          {obj.title}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="interestedcourse"
                      component="div"
                      className={styles2.error}
                    />
                  </div>
                  <div className={styles2.inputData}>
                    <label className={styles2.formLabel}>
                      Select Country <p className={styles2.formMandatoryLogo}>*</p>
                    </label>
                    <Field
                      as="select"
                      className={styles2.formSelectinput}
                      name="country"
                      onChange={(e) => {
                        setFieldValue("country", e.target.value);
                        if (e.target.value !== "INDIA") {
                          setFieldValue("status", null); // Reset status if not India
                        }
                      }}
                    >
                      <option value="" hidden label="Select Country" />
                      <option value="CANADA">CANADA</option>
                      <option value="INDIA">INDIA</option>
                      <option value="USA">USA</option>
                    </Field>
                    <ErrorMessage name="country" component="div" className={styles2.error} />
                  </div>

                  {values.country === "INDIA" && (
                    // Use the initial value for checking country
                    <div className={styles2.inputData}>
                      <label className={styles2.formLabel}>
                        Select Status <p className={styles2.formMandatoryLogo}>*</p>
                      </label>
                      <Field as="select" className={styles2.formSelectinput} name="status">
                        <option value="" label="Select Status" />
                        <option value="Full-Time Student">Full-Time Student</option>
                        <option value="Part-Time Student">Part-Time Student</option>
                        <option value="Full-Time Employed">Full-Time Employed</option>
                        <option value="Part-Time Employed">Part-Time Employed</option>
                        <option value="Unemployed">Unemployed</option>
                      </Field>
                      <ErrorMessage name="status" component="div" className={styles2.error} />
                    </div>
                  )}

                  <div className={styles2.inputData}>
                    <div className={styles2.subscribeDiv}>
                      <Field
                        type="checkbox"
                        id="emailsubscribe"
                        name="emailsubscribe"
                        className={styles2.checkbox}
                      />
                      <label htmlFor="emailsubscribe" className={styles2.text}>
                        I subscribe to send me updates and information by email.
                      </label>
                    </div>
                  </div>
                  {/* <div className={styles2.inputData}> */}
                  <div className={styles2.btnDiv}>
                    <button type="submit" className={styles2.submitBtn}>
                      <p className={styles2.submitBtnText}>Submit</p>
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}
export default Section2;
