//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, {  useLayoutEffect, useRef, useState } from "react";
import styles from '../../assets/styles/AboutUs.module.css'
import AnimatedNumber from 'react-animated-number';
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//


function Section3() {

//--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const scrollRef = useRef();
    const [foreignFollowers, setForeignFollowers] = useState(0);
    const [certifiedTeachers, setCertifiedTeachers] = useState(0);
    const [studentEnrolled, setStudentEnrolled] = useState(0);
    const [completeCourses, setCompleteCourses] = useState(0);
//--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//


//--------------------------------------------------------------------On Scroll---------------------------------------------------------------------------//
  const onScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    if (scrollPosition > 1500) {
      setForeignFollowers((h) => h + 55000);
      setCertifiedTeachers((h) => h + 60);
      setStudentEnrolled((h) => h + 2240);
      setCompleteCourses((h) => h + 215);

      window.removeEventListener("scroll", onScroll);
    }
  };
//--------------------------------------------------------------------On Scroll---------------------------------------------------------------------------//
  useLayoutEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
    return (
        <div className={styles.section3}>
            <div className={styles.bgContentDiv}>
                <div>
                    <div className={styles.animCountDiv}>
                        <AnimatedNumber
                        initialValue={5000}
                          value={foreignFollowers}
                          frameStyle={(perc) => (perc > 20 && perc < 80 ? { opacity: 0.5 } : { opacity: 1 })}
                          duration={2000}
                          formatValue={(n) => parseFloat(n).toFixed(0)}
                        />
                    </div>
                </div>
                <p className={styles.bgText}>Foreign Followers</p>
            </div>
            <div className={styles.bgContentDiv}>
                <div>
                    <div className={styles.animCountDiv}>
                        <AnimatedNumber
                        initialValue={10}
                          value={certifiedTeachers}
                          frameStyle={(perc) => (perc > 20 && perc < 80 ? { opacity: 0.5 } : { opacity: 1 })}
                          duration={2000}
                          formatValue={(n) => parseFloat(n).toFixed(0)}
                        />
                    </div>
                </div>
                <p className={styles.bgText}>Certified Teachers</p>
            </div>
            <div className={styles.bgContentDiv}>
                <div>
                    <div className={styles.animCountDiv}>
                        <AnimatedNumber
                        initialValue={2100}
                          value={studentEnrolled}
                          frameStyle={(perc) => (perc > 20 && perc < 80 ? { opacity: 0.5 } : { opacity: 1 })}
                          duration={2000}
                          formatValue={(n) => parseFloat(n).toFixed(0)}
                        />
                    </div>
                </div>
                <p className={styles.bgText}>Student Enrolled</p>
            </div>
            <div className={styles.bgContentDiv}>
                <div>
                    <div className={styles.animCountDiv}>
                        <AnimatedNumber
                        initialValue={100}
                          value={completeCourses}
                          frameStyle={(perc) => (perc > 20 && perc < 80 ? { opacity: 0.5 } : { opacity: 1 })}
                          duration={2000}
                          formatValue={(n) => parseFloat(n).toFixed(0)}
                        />
                    </div>
                </div>
                <p className={styles.bgText}>Complete Courses</p>
            </div>
        </div>
    );
}

export default Section3;