//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

import React, { useEffect, useState } from "react";
import styles from "../assets/styles/Header.module.css";
import paramLogo from "../assets/images/PCSI-logo.png";
import phoneIcon from "../assets/images/headerPhoneIcon.png";
import mailIcon from "../assets/images/headerMailIcon.png";
import facebookHeader from "../assets/images/facebookHeader.png";
import linkedinHeader from "../assets/images/linkedinHeader.png";
import instagramHeader from "../assets/images/instagramHeader.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RegisterModal from "../Pages/Registermodal/RegisterModal";
import HamburgerMenu from "./HamburgerMenu";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Header({ anim, setShowModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const navigation = useNavigate();
  const location = useLocation();
  let currentRoute = location.pathname;
  let current_route = useSelector((state) => state.allData.current_route);
  const dispatch = useDispatch();
  const loginCheck = localStorage.getItem("loginUser");
  // console.log(">>>>>>>>",loginCheck)
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //--------------------------------------------------------------------Go to top function---------------------------------------------------------------------------//

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  //--------------------------------------------------------------------Go to top function---------------------------------------------------------------------------//

  //--------------------------------------------------------------------UseEffect to dispatch current route---------------------------------------------------------------------------//

  useEffect(() => {
    dispatch({ type: "CURRENT_ROUTE", payload: currentRoute });
    topFunction();
  }, [currentRoute]);

  //--------------------------------------------------------------------UseEffect to dispatch current route---------------------------------------------------------------------------//

  return (
    <header className={styles.headerSection} id="header">
      <div className={styles.preHeaderMain}>
        <div className={styles.preHeader}>
          <div className={styles.preHeaderContent}>
            <div>
              <span className={styles.countryName}>USA:</span>
              <a href="tel:+917016693717" className={styles.headerAnchor}>
                <img src={phoneIcon} alt="headerPhoneIcon" className={styles.contentLogo} />
                <span className={styles.preHeaderContentText}>732-404-0411</span>
              </a>
              <div className={styles.contentSeperator}></div>
              <a href="mailto:infoparamedu@gmail.com" className={styles.headerAnchor}>
                <img src={mailIcon} alt="headerPhoneIcon" className={styles.contentLogo} />
                <p className={styles.preHeaderContentText}>info@paramacademy.com</p>
              </a>
            </div>

            <div className={styles.bigContentSeperator}></div>

            <div>
              <span className={styles.countryName}>INDIA:</span>
              <a href="tel:+917016693717" className={styles.headerAnchor}>
                <img src={phoneIcon} alt="headerPhoneIcon" className={styles.contentLogo} />
                <span className={styles.preHeaderContentText}>(+91) 7016693717</span>
              </a>
              <div className={styles.contentSeperator}></div>
              <a href="mailto:infoparamedu@gmail.com" className={styles.headerAnchor}>
                <img src={mailIcon} alt="headerPhoneIcon" className={styles.contentLogo} />
                <p className={styles.preHeaderContentText}>parameduindia@gmail.com</p>
              </a>
            </div>
          </div>
          <div className={styles.preHeaderContentRight}>
            <div className={styles.socialContainer}>
              <div className={loginCheck ? styles.headerTwobtnsStyle : styles.headerTwobtns}>
                {loginCheck ? (
                  <>
                    <label className={styles.loginName}>{loginCheck}</label>
                    <button
                      className={styles.registerBtn}
                      onClick={() => {
                        localStorage.removeItem("loginUser");
                        navigation("/");
                      }}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={styles.registerBtn}
                      onClick={() => {
                        setShowModal("Register");
                      }}
                    >
                      Register
                    </button>
                    <button
                      className={styles.registerBtn}
                      onClick={() => {
                        setShowModal("Login");
                      }}
                    >
                      Login
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headerTwoMain}>
        <div className={styles.header}>
          <button
            className={styles.logoDiv}
            onClick={() => {
              navigation("/");
              topFunction();
            }}
          >
            <img src={paramLogo} className={styles.logo} alt="" />
          </button>
          <div className={styles.navbar}>
            <div className={styles.navItemDiv}>
              <a href="">
                <button
                  className={styles.navBtn}
                  onClick={() => {
                    navigation("/");
                    document.body.scrollTop = 3000;
                    document.documentElement.scrollTop = 3000;
                  }}
                >
                  <p
                    className={
                      current_route == "/"
                        ? styles.navText + " " + styles.activeTab
                        : styles.navText
                    }
                  >
                    Home
                  </p>
                </button>
              </a>
            </div>
            <div className={styles.navItemDiv}>
              <button
                className={styles.navBtn}
                onClick={() => {
                  navigation("/courses");
                }}
              >
                <p
                  className={
                    current_route == "/courses"
                      ? styles.navText + " " + styles.activeTab
                      : styles.navText
                  }
                >
                  Courses
                </p>
              </button>
            </div>
            {/* <div className={styles.navItemDiv}>
              <button
                className={styles.navBtn}
                onClick={() => {
                  navigation("/inquiry");
                }}
              >
                <p
                  className={
                    current_route == "/inquiry"
                      ? styles.navText + " " + styles.activeTab
                      : styles.navText
                  }
                >
                  Inquiry
                </p>
              </button>
            </div> */}
            <div className={styles.navItemDiv}>
              <button
                className={styles.navBtn}
                onClick={() => {
                  navigation("/aboutus");
                }}
              >
                <p
                  className={
                    current_route == "/aboutus"
                      ? styles.navText + " " + styles.activeTab
                      : styles.navText
                  }
                >
                  About Us
                </p>
              </button>
            </div>
            <div className={styles.navItemDiv}>
              <button
                className={styles.navBtn}
                onClick={() => {
                  navigation("/timings");
                }}
              >
                <p
                  className={
                    current_route == "/timings"
                      ? styles.navText + " " + styles.activeTab
                      : styles.navText
                  }
                >
                  Timings
                </p>
              </button>
            </div>
          </div>
          <div className={styles.btnDiv}>
            <button
              className={styles.contactUsBtn}
              onClick={() => {
                navigation("/inquiry");
                // navigation("/contactus");
              }}
            >
              {/* <p className={styles.contactBtnText}>Contact Us</p> */}
              <p className={styles.contactBtnText}>Inquire Now</p>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.hamburgerHeader}>
        <button
          className={styles.logoDiv}
          onClick={() => {
            navigation("/");
            topFunction();
          }}
        >
          <img src={paramLogo} className={styles.logo} alt="" />
        </button>

        <HamburgerMenu />
      </div>
    </header>
  );
}

export default Header;
