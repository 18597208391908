//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../assets/styles/HomePage.module.css";
import Section2 from './HomePage/Section2';
import Section3 from "./HomePage/Section3";
import Section1 from './HomePage/Section1';

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function HomePage({setShowModal}) {
 
  return (
    <div className={styles.sectionContainer}>
   
      <Section1 setShowModal={setShowModal}/>
      <Section2 />
      <Section3 setShowModal={setShowModal}/>
  </div>
);
}

export default HomePage;
