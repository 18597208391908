// -------------------------------------------------------------------- All Imports Starts ------------------------------------------------------------------------------------------//
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../Footer";
import RegisterModal from "../../Pages/Registermodal/RegisterModal";
import Header from "../Header";
import LockContentModal from "../../Pages/Coursecontent/LockContentModal";
// -------------------------------------------------------------------- All Imports Ends ------------------------------------------------------------------------------------------//

const PublicRoute = ({
  setShowModal,
  showModal,
  setShowLockModal,
  showLockModal,
}) => {
  // -------------------------------------------------------------------- States, Variable Initialization Starts ------------------------------------------------------------------//
  let role = useSelector((state) => state.allData.user_role);
  // -------------------------------------------------------------------- States, Variable Initialization Ends ------------------------------------------------------------------//

  console.log("its here");

  // -------------------------------------------------------------------- In Return Block, Checking Valid Routes for Users ------------------------------------------------------------------//
  return role != 1 || role != 2 || role != 3 ? (
    <div>
      {showModal && (
        <RegisterModal setShowModal={setShowModal} showModal={showModal} />
      )}
      {showLockModal && (
        <LockContentModal
          setShowLockModal={setShowLockModal}
          showLockModal={showLockModal}
          setShowModal={setShowModal}
        />
      )}
      <Header setShowModal={setShowModal} />
      <Outlet />
      <Footer setShowModal={setShowModal} />
    </div>
  ) : role == 1 ? (
    <Navigate to="admin/courses" />
  ) : (
    <Navigate to="/" />
  );
};

export default PublicRoute;
