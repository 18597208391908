import React from 'react'
import styles from '../../assets/styles/LockContentModal.module.css'
import crossIcon from '../../assets/images/crossIcon.png'

function LockContentModal({showLockModal ,setShowLockModal,setShowModal}) {
  return (
    <div className={styles.MaindivGreyBG} onClick={()=>{setShowLockModal(false);}}>
        <div className={styles.subMainDiv}>
            <div className={styles.crossIconFlex}> 
            <img src={crossIcon} alt="Home Backgroud Image" className={styles.cardImg}    onClick={() => setShowLockModal(false)}/>
            </div>
            <div className={styles.subFlex}>
                <div className={styles.contentDiv}> 
                    This content is protected, please
                    <a className={styles.loginStyle}   
                        onClick={() =>{
                                setShowLockModal(false);    
                                setShowModal("Login");
                    }}> Login </a> and enroll in the course to view this content!
                </div>
                <div>
                    <button className={styles.loginBtn}
                             onClick={() =>{
                                setShowLockModal(false);    
                                setShowModal("Login");
                    }}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LockContentModal